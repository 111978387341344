import * as util from './util';
import { LogLevel } from './logger';
import { IStudyMap } from './library/LibraryRegister';
import { bool } from 'aws-sdk/clients/signer';

export let isDev = true;
export let isTest = true;

export const VERSION = "152";

export const REACT_APP_APISERVER_DEV = "https://visangcbs.link";

export const REACT_APP_APISERVER_REAL = "https://cmsapi.vsaidt.com";
export const REACT_APP_APISERVER_TEST = "https://t-cmsapi.vsaidt.com";

export const REACT_APP_EDITOR_DEV = "https://zoom-video.s3.ap-northeast-2.amazonaws.com";
// export const REACT_APP_EDITOR_DEV = "https://t-cms.vsaidt.com";
export const REACT_APP_EDITOR_REAL = "https://cms.vsaidt.com";
export const REACT_APP_EDITOR_TEST = "https://t-cms.vsaidt.com";

export let APISERVER_HOST = isDev ? REACT_APP_APISERVER_DEV : isTest ? REACT_APP_APISERVER_TEST : REACT_APP_APISERVER_REAL;
export let EDITOR_HOST = isDev ? REACT_APP_EDITOR_DEV : isTest ? REACT_APP_EDITOR_TEST : REACT_APP_EDITOR_REAL;

export const S3Server = "https://t-cms.vsaidt.com/";

/** The App environment */
export type Environment = 'development' | 'production';
export const APP_ENV: Environment = !isDev ? 'production' : 'development'; 
export const LOG_LEVEL: LogLevel = APP_ENV === 'production' ? 'warn' : 'log';

export const leftmenu_1 = ["라이브러리 관리", "콘텐츠 관리", "교과서 관리", "교재 관리", "교수자료 관리", "교과도구 관리", "신고 콘텐츠 관리", "메타 정보 관리", "AI 튜터 관리", "시스템 관리"];
export const leftmenu_2 = [
                            ["이미지 관리", "오디오 관리", "비디오 관리", "텍스트 관리", "어휘 관리", "문장 관리", "지문 관리", "영어 교과 전용 템플릿", "예외 규칙 관리​", "이미지 설명/동영상 해설 일괄등록"], 
                            ["나의 저작", "콘텐츠 목록", "수업자료 목록", "템플릿 관리", "배치작업", "수업자료(영상)일괄등록", "유사문항 관리​"], 
                            ["웹교과서 관리", "e book 교과서 관리", "배포 관리"], 
                            ["교재 관리"], 
                            ["i-book 관리", "콘텐츠 book 관리", "콘텐츠 관리"],
                            ["수학도구 관리​", "교과템 도구 관리"],
                            ["신고 목록"],
                            ["교육과정 년도 관리", "학교급 관리", "교과목 관리", "학년 관리", "학기 관리", "교과과정 관리", "콘텐츠 형식 관리", "교과서 유형 관리​", 
                            "세트지 유형 관리", "교과역량 관리", "문항유형 관리", "평가영역1 관리", "난이도 관리", "내용영역 관리", "교과/비교과 관리​", 
                            "문항 채점방식 관리​", "학습맵 관리", "템플릿 구분값 관리", "평가영역2 관리", "평가영역3 관리", "단원그룹 관리", 
                            "성취기준 관리​", "소재 관리", "의사소통기능 관리", "언어형식 관리",
                            "교수자료 유형", "교수자료 용도", "교수자료 과목",
                            "도서분류 관리", "자료년도 관리", "자료유형 관리", "출처(브랜드) 관리"],
                            ["튜터 콘텐츠 관리", "주제 관리​", "자유대화 주제", "작문 주제"],
                            ["브랜드 관리", "권한 그룹 관리", "관리자 계정관리", "내 계정 관리"]];

export const MetaMenuInfo = [  
                                {name:"curriYear", title:"교육과정 년도 관리"}, 
                                {name:"curriSchool", title:"학교급 관리"}, 
                                {name:"curriSubject", title:"교과목 관리"}, 
                                {name:"curriGrade", title:"학년 관리"}, 
                                {name:"curriSemester", title:"학기 관리"}, 
                                {name:"curriBook", title:"교과과정 관리", type:"H"}, 
                                {name:"articleType", title:"콘텐츠 형식 관리"}, 
                                {name:"textbookCategory", title:"교과서 유형 관리​"}, 
                                {name:"setCategory", title:"세트지 유형 관리"}, 
                                {name:"subjectAbility", title:"교과역량 관리", type:""}, 
                                {name:"questionType", title:"문항유형 관리"}, 
                                {name:"evaluationArea", title:"평가영역1 관리"}, 
                                {name:"difficulty", title:"난이도 관리"}, 
                                {name:"contentArea", title:"내용영역 관리", type:""}, 
                                {name:"articleCategory", title:"교과/비교과관리"}, 
                                {name:"gradingMethod", title:"문항 채점방식 관리"},
                                {name:"studyMap", title:"학습맵 관리", type:"H"}, 
                                {name:"templateDiv", title:"템플릿 구분값 관리", type:"H"}, 
                                {name:"contentsItem", title:"평가영역2 관리", type:"H"}, 
                                {name:"evaluationArea3", title:"평가영역3 관리", type:""},
                                {name:"unitGroup", title:"단원그룹 관리", type:""}, 
                                {name:"achievementStandard", title:"성취기준 관리", type:"H"}, 
                                {name:"material", title:"소재 관리", type:"H"}, 
                                {name:"communication", title:"의사소통기능 관리", type:"H"}, 
                                {name:"languageFormat", title:"언어형식 관리", type:"H"}, 
                                {name:"teachingCategory", title:"교수자료 유형", type:""}, 
                                {name:"teachingUsage", title:"교수자료 용도​", type:""}, 
                                {name:"teachingSubject", title:"교수자료 과목", type:""}, 
                                {name:"visangbookCategory", title:"도서분류", type:""}, 
                                {name:"dataYear", title:"자료년도", type:""}, 
                                {name:"dataCategory", title:"자료유형​", type:""}, 
                                {name:"dataBrand", title:"출처(브랜드)", type:""}, 
                            ];
    
export const type1List = ["이미지", "오디오", "비디오", "텍스트"];
export const type1ListValue = ["image", "audio", "video", "text"];
export const _type2List = [[], [], [], ["어휘", "문장", "지문"]];
export const _type3TextList = [[], [], ["대화형", "일반형", "혼합형"]];

export const authGroupInfo = ["Library", "Contents", "Book", "VisangBook", "Teaching", "Instrument", "Claim", "Meta", "AITutor", "System"];
export const authGroupInfo_new = [["Library:이미지 관리", "Library:오디오 관리", "Library:비디오 관리", "Library:텍스트 관리", "Library:어휘 관리", "Library:문장 관리", "Library:지문 관리", "Library:영어 교과 전용 템플릿", "Library:예외 규칙 관리​", "Library:이미지 설명/동영상 해설 일괄등록"], 
                            ["Contents:나의 저작", "Contents:콘텐츠 목록", "Contents:수업자료 목록", "Contents:템플릿 관리", "Contents:배치작업", "Contents:수업자료(영상)일괄등록", "Contents:유사문항 관리​"], 
                            ["Book:웹교과서 관리", "Book:e book 교과서 관리", "Book:배포 관리"], 
                            ["VisangBook:교재 관리"], 
                            ["Teaching:i-book 관리", "Teaching:콘텐츠 book 관리", "Teaching:콘텐츠 관리"],
                            ["Instrument:수학도구 관리​", "Instrument:교과템 도구 관리"],
                            ["Claim:신고 목록"],
                            ["Meta:교육과정 년도 관리", "Meta:학교급 관리", "Meta:교과목 관리", "Meta:학년 관리", "Meta:학기 관리", "Meta:교과과정 관리", "Meta:콘텐츠 형식 관리", "Meta:교과서 유형 관리​", 
                            "Meta:세트지 유형 관리", "Meta:교과역량 관리", "Meta:문항유형 관리", "Meta:평가영역1 관리", "Meta:난이도 관리", "Meta:내용영역 관리", "Meta:교과/비교과 관리​", 
                            "Meta:문항 채점방식 관리​", "Meta:학습맵 관리", "Meta:템플릿 구분값 관리", "Meta:평가영역2 관리", "Meta:평가영역3 관리", "Meta:단원그룹 관리", 
                            "Meta:성취기준 관리​", "Meta:소재 관리", "Meta:의사소통기능 관리", "Meta:언어형식 관리",
                            "Meta:교수자료 유형", "Meta:교수자료 용도", "Meta:교수자료 과목",
                            "Meta:도서분류 관리", "Meta:자료년도 관리", "Meta:자료유형 관리", "Meta:출처(브랜드) 관리"],
                            ["AITutor:튜터 콘텐츠 관리", "AITutor:주제 관리​", "AITutor:자유대화 주제", "AITutor:작문 주제"],
                            ["System:브랜드 관리", "System:권한 그룹 관리", "System:관리자 계정관리", "System:내 계정 관리"]];

export interface IGrammarTemplate {
    id: number;
    name: string;
    val: string;
}

// export interface IGrammarTemplate {
//     id: number;
//     val: string;
//     depth: number;
//     parent: string;
//     code: string;
//     articles: string;
// }

export interface IType123 {
    id: number;
    val: string;
    parent: number;
}

export function headers() {
    const headers = {
        "Authorization": util.getUserInfo("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, PUT",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
        "Access-Control-Max-Age": "1800",
    }
    return headers;
}

export interface IMenu {
    menu1: number;
    menu2: number;
}

export interface IUserAuthGroupMap {
	user: IUser;
	authgroup: IAuthGroup[];
}

export interface IMetaMap {
	meta: IMeta;
	metamapList: IMeta[];
    articles?: IArticle[];
}

export interface CurriInfo {
	name: string;
	title: string;
	list: IMeta[];
}
  
export interface ILibraryFile {
	type?: string;
	file?: File | undefined;
    url: string;
    thumbnail: string;
    isDelete?: boolean;
    isUploaded?: boolean;
}

export interface IArticle {
    articleCategory: number;
    articleType: number;
    brand_id: number;
    contentArea: number;
    creator: string;
    creator_id: number;
    creator_name: string;
    curriBook: number;
    curriGrade: number;
    curriSchool: number;
    curriSemester: number;
    curriSubject: number;
    curriUnit1: number;
    curriUnit2: number;
    curriUnit3: number;
    curriUnit4: number;
    curriUnit5: number;
    curriYear: number;
    description: string;
    difficultyVal: string;
    full_count: number;
    open_count: number;
    hashTag: any;
    hashTags: string;
    id: number;
    image: string;
    is_active: boolean;
    is_editable: boolean;
    is_publicOpen: boolean;
    name: string;
    questionStr: string;
    questionType: number;
    regdate: string;
    subjectAbility: number;
    thumbnail: string;
    updatedate: string;
    updater: string;
    updater_id: number;
    updater_name: string;
    url: string;
    version: number;
    review: number;
    reviewStatus: number;
    libraryMap: ILibrary[];
    libtextMap: ILibrary[];
    metaMap: IMeta[];

    hintYN: string;
    commentaryYN: string;
    explainationYN: string;
    sbsChatting: string;
    sbsSolution: string;
    sbsChattingYN: string;
    sbsSolutionYN: string;
    meta_code: string;

    content_type: string;
    articleUpdateFlag: boolean;

    deployStatus: number;

    sub_id: number;
    setSummary_name: string;
}

export interface ISet {
    articles: IArticle[];
    brand_id: number;
    creator: string;
    creator_id: number;
    creator_name: string;
    curriBook: number;
    curriUnit1: number;
    curriUnit2: number;
    curriUnit3: number;
    curriUnit4: number;
    curriUnit5: number;
    description: string;
    difficulty: string;
    full_count: number;
    hashTag: any;
    hashTags: string;
    id: number;
    image: string;
    is_active: boolean;
    is_editable: boolean;
    is_publicOpen: boolean;
    loginUserId: string;
    metaMap: IMeta[];
    name: string;
    open_count: number;
    regdate: string;
    setCategory: number;
    updatedate: string;
    updater: string;
    updater_id: number;
    updater_name: string;
    version: number;
    thumbnail: string;

    deployStatus: number;

    is_materialOpen: boolean;

}

export interface ITextBookTab {
    id?: number;
    brand_id: number;
    name: string;
    is_active: boolean;
    is_publicOpen: boolean;
    creator: string;
    creator_id: number;
    creator_name: string;
    updater: string;
    updater_id: number;
    updater_name: string;
    regdate?: string;
    updatedate?: string;
    version: number;
    textbookIndex_id: number;
    textbookCurriculum_id: number;
    textbookCurriculum_key: number;
    set_id: number;
    accessLevel: number;
    source: string;
    setCategory: number;

    ebook_id: number;
    startPage: number;
    endPage: number;
    is_ebook_active: boolean;

    theme_code: string;
    theme_path: string;
    theme_desc: string;
    
    isDirty: boolean;
}

export interface ITextBookIndex {
    id?: number;
    brand_id: number;
    name: string;
    max_depth: number;
    is_active: boolean;
    is_publicOpen: boolean;
    creator: string;
    creator_id: number;
    creator_name: string;
    updater: string;
    updater_id: number;
    updater_name: string;
    regdate?: string;
    updatedate?: string;
    version?: number;
    curriculumList?: string;
    full_count?: number;
    version_contents?: number;
    deployVersion_textbookConfig?: number;
    deployVersion_textbookIndex?: number;
    deployVersion_textbookContents?: number;
    versionHistory?: number;
    open_count?: number;
    textbookCurriculumList?: any[];
    textbook_id?: number;
    curriBook?: number;
}

export interface ITextbookBasicData {
    id?: number;
    textbookIndex_id?: number;
    type: string;
    brand_id: number;
    name: string;
    language: string;
    supportPdf: boolean;
    accessLevel: number;
    textbookCategory: number;
    // curriYear: number;
    curriBook: number;

    creator: string;
    creator_id: number;
    creator_name: string;

    updater: string;
    updater_id: number;
    updater_name: string;

};

export interface ITextBook {
    id?: number;
    type: string;
    textbookIndex_id: number | undefined;
    version?: number;
    brand_id: number;
    name: string;
    language: string;
    supportPdf: boolean;
    accessLevel: number;
    textbookCategory: number;
    curriYear: number;
    curriBook: number;
    description?: string;
    full_count?: number;
    creator: string;
    creator_id: number;
    creator_name: string;
    regdate: string;
    updatedate: string;
    updater: string;
    updater_id: number;
    updater_name: string;
    is_active: boolean;
    is_publish: boolean;
    deployStatus: number;
    web_textbook_id?: number;
    data_type: string;
    curriSubject: number;
    curriSchool: number;
    metaMap: IMeta[];
    maxPages: string;
    hashTag: string[];

    isValid: boolean;

    textbookEvaluationCount: number;

    image_url: string;
    
    pdf_url: string;

    dirty: boolean;
};

export interface ITextBookEvaluation {
    id: number;
    textbookId: number;
    matrialType: number;    //과제:1 / 평가:2​
    brand_id: number;
    matrialNm: string;

    evlSeCd: number;    //1:진단평가, 2:형성평가, 3:총괄평가, 4:기타​

    creator: string;
    creator_id: number;
    creator_name: string;
    regdate: string;

    updatedate: string;
    updater: string;
    updater_id: number;
    updater_name: string;

    set_id: number;
    set_name: string;
    timTime: string;

    is_active: boolean;
    is_publicOpen: boolean;
};
 
export interface IAITutor {
	id: number;

    creator: string;
	creator_id: number;
	creator_name: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
	regdate: string;
    description: string;
    is_active: boolean;
    is_publicOpen: boolean;

    curriBook: number;
    curriUnit1: number;
    curriUnit2: number;
    curriUnit3: number;
    curriUnit4: number;
    curriUnit5: number;

    studyMap: number;
    studyMap1: number;

	type: string;
	ai: string;
    student: string;
    communication: string;
    mission: string;
    scenario: string;
    theme: string;
    writing: string;

    full_count: number;
    metaMap: IMeta[];
}

export interface ILibrary {
	id: number;
	type_1: string;
	type_2: string;
	type_3?: string;
	code: string;
	name: string;
	creator: string;
	creator_id: number;
	creator_name: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
	regdate: string;
	url: string;
    thumbnail: string;
    description: string;
    is_active: boolean;
    is_publicOpen: boolean;
    used_in: string;

    curriBook: number;
    curriUnit1: number;
    curriUnit2: number;
    curriUnit3: number;
    curriUnit4: number;
    curriUnit5: number;

    full_count: number;
    metaMap: IMeta[];
    libtextMap: IText[];
    studyMap: IStudyMap[];
    studyMapListString: string;

    script_id: number;
}

export interface IReport {
	id: number;
	type: string;
    status: string;
    category: string;
    subject: string;
	school_name: string;
	creator: string;
	creator_id: number;
	creator_name: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
	regdate: string;
	updatedate: string;
    description: string;

    full_count: number;
}

export interface IEngTemp {
	id: number;
	type_1: string;
	type_2: string;
	type_3: string;
	name: string;
	creator: string;
	creator_id: number;
	creator_name: string;
	regdate: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
    updatedate?: string;
	url: string;
    thumbnail: string;
    is_active: boolean;
    is_publicOpen: boolean;

    curriBook: number;
    curriUnit1: number;
    curriUnit2: number;
    curriUnit3: number;
    curriUnit4: number;
    curriUnit5: number;

    full_count: number;
    metaMap: IMeta[];
    mediaKey: string;
    description: string;

    studyMap: IStudyMap[];
    studyMapListString: string;

    audioKey: ILibrary;
    videoKey: ILibrary;
    textKey: IText[];

    children: string;
    tempDepth: number;
    tempListString: string;

    evaluationArea1String: string;
    evaluationArea2String: string;
    evaluationArea3String: string;
}

export interface IText {
	id: number;
	type_1: string;
	type_2: string;
	code: string;
	name: string;
	creator: string;
	creator_id: number;
	creator_name: string;
	regdate: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
    updatedate?: string;

	url: string;
    thumbnail: string;
    is_active: boolean;
    is_publicOpen: boolean;
    used_in: string;

    curriBook: number;
    curriUnit1: number;
    curriUnit2: number;
    curriUnit3: number;
    curriUnit4: number;
    curriUnit5: number;

    full_count: number;
    metaMap: IMeta[];

    parts: string;

    contents_entry: string;
    contents_multiLang: string;
    contents_def: string;
    contents_pron: string;
    contents_image: string;
    contents_audio: string;
    contents_video: string;
    contents_audioAnalysis: string;

    speaker: string;
    info1: string;
    info2: string;
    info3: string;
    chunk1: string;
    chunk2: string;
    key_info: string;
    key_type: string;
    key_title: string;
    key_desc: string;

    exam_sentences: IText[];
    articles: IArticle[];
    studyMap: IStudyMap[];
    dialog: IScript[];
    studyMapListString: string;

}

export interface IScript {
    id?: number;
    pkey: string;
    skey: string;
    type: string;
    speaker: string;
    entry: string;
    multiLang: string;
    startTime: string;
    endTime: string;
    akey: string;
    summary: string;
    exceptEmpty: string;
}

export interface IUser {
	id: number | undefined;
	uid: string;
	password: string;
	name: string;
	email?: string;
	team?: string;
	authgroup: string;
	creator: string;
	creator_id: number;
	creator_name: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
	regdate?: string;
	updatedate?: string;
	is_active: boolean;
    role: string;
}

export interface IBrand {
	id?: number;
	code: string;
	name: string;
	is_active: boolean;
	creator?: string;
	creator_id?: number;
	creator_name?: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
	regdate?: string;
	updatedate?: string;
	description?: string;
}

export interface IAuthGroup {
	id?: number;
	code: string;
	name: string;
	auth: string;
	is_active: boolean;
	creator: string;
	creator_id: number;
	creator_name: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
	regdate?: string;
	updatedate?: string;
	description?: string;
	brand_id?: number | null;
	brand_name: string;
}

export interface ICurriInfo {
    all: IMeta[];

    curriBook: IMeta[];
    // curriUnit1: IMeta[];
    // curriUnit2: IMeta[];
    // curriUnit3: IMeta[];
    // curriUnit4: IMeta[];
    // curriUnit5: IMeta[];
    // curriUnit6: IMeta[];

    curriYear: IMeta[];
    curriSchool: IMeta[];
    curriSubject: IMeta[];
    curriGrade: IMeta[];
    curriSemester: IMeta[];
    difficulty: IMeta[];
    articleCategory: IMeta[];
    articleType: IMeta[];
    textbookCategory: IMeta[];
    setCategory: IMeta[];
}

export interface IMeta {
	id?: number;
	code: string;
	name: string;
	val: string;
    title?: string;
	depth: number;
    max_depth?: number;
	brand_id: number | null;
	parent_id: number;
	is_active: boolean;
	creator: string;
    creator_id: number;
	creator_name: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
	regdate?: string;
	description?: string;
	uuid?: string;

    name1?: string;
    name2?: string;
    name3?: string;
    name4?: string;
    name5?: string;
    name6?: string;
    name7?: string;
    name8?: string;
    name9?: string;
    name10?: string;

    val1?: string;
    val2?: string;
    val3?: string;
    val4?: string;
    val5?: string;
    val6?: string;
    val7?: string;
    val8?: string;
    val9?: string;
    val10?: string;

    isUsingStudyMap?: boolean;

    isOpened?: boolean;
    hasChild?: boolean;
    isVisible?: boolean;
}

export interface ILibrarySearchData {
    type: string;
    type_1: string;
    type_2?: string;
    type_3?: string;
    brand_id: number | null;
    reg_sdate?: string;
    reg_edate?: string;
	creator?: string;
	creator_id?: number;
	creator_name?: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
    id?: number;
    search?: string;
    searchField?: string;
    curriBook?: number;
    curriUnit1?: number;
    curriUnit2?: number;
    curriUnit3?: number;
    curriUnit4?: number;
    curriUnit5?: number;
    // meta?: IMeta;

    orderby_col?: string;
    orderby_dir?: string;

    limit_page?: number;
    limit_pageItemCnt?: number;

}

export interface IRole {
    aid: number;
    bid: number;
    role: string;
}

export interface IUserBrandAuth {
    user: IUser[];
    brand: IBrand[];
    authgroup: IAuthGroup[];
}

export interface IInstrument {
	id: number;
	name: string;
	code: string;
	type: string;
    description: string;

    introFileUrl: string;
    fontCssUrl: string;
    imageUrl: string;
    width: string;
    height: string;
    tag: string;
    params: string;
    meta: string;
    module: boolean;
    scale: boolean;
    subjectTemplate: boolean;
    includeType: string;

    creator: string;
	creator_id: number;
	creator_name: string;
	regdate: string;
	updater?: string;
	updater_id?: number;
	updater_name?: string;
    updatedate?: string;

    is_active: boolean;
    is_publicOpen: boolean;

    curriBook: number;
    curriUnit1: number;
    curriUnit2: number;
    curriUnit3: number;
    curriUnit4: number;
    curriUnit5: number;

    full_count: number;
    metaMap: IMeta[];

}

export interface ITextBookHistory {
    textbookCurriculumId: number;
    textbookCurriculumName: string;
    textbookTabId: number;
    textbookTabName: string;
    textbookSetId: number;
    textbookSetName: string;
    textbookArticleCount: number;
    dirty: boolean;
    contentsHistoryExist: boolean;
    setsDeploy: string;
}

export const latex_delimiters = [
                                    { left: '$$', right: '$$', display: true },
                                    { left: '\\(', right: '\\)', display: false },
                                    { left: '$', right: '$', display: false },
                                    { left: '\\[', right: '\\]', display: true },
                                ];

export const latex_macros = { '\\neq': '\\mathrel{\\char`≠}' };
