import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ICurriInfo, ILibrary, ILibrarySearchData, IMenu, IMeta, IScript, IText, S3Server } from "../common";
import { logger } from '../logger';
import '../common.scss';
import { ITempDiv2EV1 } from "./LibraryEngTempRegister";

interface IPopupScriptSearch {
    brand: IBrand;
    searchType: string;
    selectedScriptId: number;
    selectedItem: ITempDiv2EV1;
    onClose: () => void;    
    onSelect: (scripts: IScript[]) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function PopupScriptSearch(props: IPopupScriptSearch) {
    
    const [list, setList] = useState([] as any);
    const [script, setScript] = useState<IText>(null as any);
    const [viewList, setViewList] = useState(false);

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setViewList(true);
            logger.log("props.selectedScriptId", props.selectedScriptId, props.selectedItem);
            refreshList();
        }
        bDone = true;
    }, [])

    const refreshList = () => {
        onSearch();
    }

    async function onSearch() {

        props.showLoading(true);

        setViewList(false);

        const item = await util.getTextInfo(props.selectedScriptId);    
        logger.log("onSearch", item);

        if(item) {
            setScript(item as IText);    
            setViewList(true);    
        }

        props.showLoading(false);
    }
    
    function onSave() {

        const scripts : IScript[] = [];
        const list_ck = document.getElementsByName("list_ck");

        let checkedCnt = 0;
        for (let i = 0; i < list_ck.length; i++) {
            const element = list_ck[i] as HTMLInputElement;
            if(element.checked) {
                checkedCnt++;
                scripts.push(script.dialog[i]);
            }
        }

        logger.log("onSave", scripts);

        props.onSelect(scripts);
    }

    return (

        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box w-avail" style={{height:"100%", minHeight:"40rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={props.onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">{"오브젝트 검색"}</h2>

                            <div className="px-5">

                                <div>
                                    <div className="py-5">
                                    
                                        {viewList && <>

                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="w-56">타이틀</th>
                                                    <td className="text-left">{script.name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56">KEY</th>
                                                    <td className="text-left">{script.id}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/* <!-- table --> */}
                                        <table className="mt-5">
                                            <thead>
                                                <tr>
                                                    <th style={{display:""}} scope="col" className="text-center">
                                                        <input id="all_check" className="h-4 w-4 rounded mt-2" type="checkbox" onClick={(e) => {
                                                        const list_ck = document.getElementsByName("list_ck");
                                                        for (let i = 0; i < list_ck.length; i++) {
                                                            const element = list_ck[i] as HTMLInputElement;
                                                            if(!element.disabled) {
                                                                element.checked = (e.target as HTMLInputElement).checked;
                                                            }                                    
                                                        }
                                                    }} /></th>
                                                    <th scope="col" className="px-3 text-center">문장Key</th>
                                                    <th scope="col" className="px-3 text-center">Speaker</th>
                                                    <th scope="col" className="px-3 text-center w-[40%]" style={{fontSize: "0.85em"}}>Entry</th>
                                                    <th scope="col" className="px-3 text-center w-[40%]" style={{fontSize: "0.85em"}}>다국어</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {viewList && script && (script.dialog as IScript[]).map((item, idx) => {
                        
                                                    let bFound = false;

                                                    if(props.selectedItem) {
                                                        const _scripts = props.selectedItem.scripts;
                                                        if(_scripts) {
                                                            if(_scripts.find((_script) => _script.id === item.id)) {
                                                                bFound = true;
                                                            }
                                                        }
                                                    }
            
                                                    return (
                                                        <tr key={idx}>                        
                                                            <td style={{display:""}}>
                                                                <input id={"list_ck_"+idx} name="list_ck" type="checkbox" className="h-4 w-4 rounded mt-2" defaultChecked={bFound} onClick={(e) => {
                                                                    const list_ck = document.getElementsByName("list_ck");
                                                                    let cntChecked = 0;
                                                                    for (let i = 0; i < list_ck.length; i++) {
                                                                        const element = list_ck[i] as HTMLInputElement;
                                                                        if(element.checked) {
                                                                            cntChecked = cntChecked + 1;
                                                                        }
                                                                    }
                                                                    const all_check = document.getElementById("all_check") as HTMLInputElement;
                                                                    if(cntChecked === list_ck.length) {                                                
                                                                        all_check.checked = true;                                                
                                                                    } else {
                                                                        all_check.checked = false;                                                
                                                                    }
                                                                }}/>
                                                            </td>
                                                            <td className="">{item.skey}</td>
                                                            <td className="text_left">{item.speaker}</td>
                                                            <td className="text_left"><textarea className="w-avail" defaultValue={item.entry}></textarea></td>
                                                            <td className="text_left"><textarea className="w-avail" defaultValue={item.multiLang}></textarea></td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                        {/* <!-- .//table -->	 */}
                                        </>}

                                        <div className="relative mt-5">
                                            <div className="text-center">
                                                <button type="button" className="btn-sky" onClick={onSave}>저장</button>
                                                <button type="button" className="btn-sky ml-3" onClick={() => {
                                                    props.onClose();
                                                }}>취소</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>

        </div>
    );  
}

export default PopupScriptSearch;
