import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ICurriInfo, IGrammarTemplate, ILibrary, ILibraryFile, IMeta, IText } from "../common";
import { logger } from '../logger';
import * as XLSX from 'xlsx';
import CircularProgress from "@mui/material/CircularProgress";
import { IStudyMap } from "./LibraryRegister";
import { ITempDiv2EV1, ITempDiv2EV3 } from "./LibraryEngTempRegister";

interface ILibraryEngTempBulkRegister {
    type: string;
    brand: IBrand;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function LibraryEngTempBulkRegister(props: ILibraryEngTempBulkRegister) {

    const [fileContent, setFileContent] = useState(null as any);
    const [fileUploading, setFileUploading] = useState(false);

    let prevView = false;
    
    const currMenu = util.getCurrentMenu();

    useEffect(() => {
        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.OnClose();
        //     return;
        // }    
    }, [])

    useEffect(() => {
        logger.log("props.type", props.type);
    }, [props.type])

    function OnClose(): void {
        props.OnClose();
    }

    async function getDescendantsList(code: string) {

        props.showLoading(true);
        const list = await util.getDescendantsList(code);
        props.showLoading(false);

        return list;
    }

    async function getMetaList(depth: string, name: string) {
        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const __list = _list.filter((item) => item.is_active === true);

        return __list;
    }

    async function onExcelProccessing_Grammar(e: ProgressEvent<FileReader>) {

        if(e.target) {

            setFileUploading(true);

            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            const templateDiv = await util.getMetaList("1", "templateDiv");

            for (let i = 4; i < dataParse.length; i++) {
                const item = dataParse[i];

                const _item = item as string[];

                if(_item.length > 0) {

                    let id_Val = undefined;

                    let brand_id_Val = undefined;

                    let creator_Val = undefined;
                    let curriBook_Val = undefined;
                    let curriUnit1_Val = undefined;
                    let curriUnit2_Val = undefined;
                    let curriUnit3_Val = undefined;
                    let curriUnit4_Val = undefined;
                    let description_Val = undefined;

                    let evaluationArea2String_Val = undefined;

                    let name_Val = undefined;
                    let type_1_Val = undefined;
                    let is_active_Val = undefined;
                    let is_publicOpen_Val = undefined;
        
                    let tempListString_Val = undefined;
        
                    const listMetaMap : IMeta[] = [];
                    const tempDiv : IMeta[] = [];

                    brand_id_Val = _item[0];
                    id_Val = _item[1] && Number(_item[1]) > 0 ? _item[1] : undefined;
                    name_Val = _item[2];
                    description_Val = _item[3];
                    listMetaMap.push({id: Number(_item[4])} as IMeta);
                    listMetaMap.push({id: Number(_item[5])} as IMeta);                
                    curriBook_Val = _item[6];
                    curriUnit1_Val = _item[7];
                    curriUnit2_Val = _item[8];
                    curriUnit3_Val = _item[9];
                    curriUnit4_Val = _item[10];
                    type_1_Val = _item[11];
                    const _templateDiv = templateDiv.find((meta) => meta.val === String(_item[11]));
                    if(_templateDiv && _templateDiv.code) {
                        listMetaMap.push(_templateDiv);
                        const list = await getDescendantsList(_templateDiv?.code);
                        list.map((item) => {
                            tempDiv.push(item);
                        });
                    }
                    evaluationArea2String_Val = _item[12];

                    const arrGrammarTemplate : IGrammarTemplate[] = [
                        {id: 0, name: "문법 타이틀", val: _item[13] ? _item[13] : ""}, 
                        {id: 1, name: "개념 학습", val: _item[14] ? _item[14] : ""}, 
                        {id: 2, name: "연습 문제1", val: _item[15] ? _item[15] : ""}, 
                        {id: 3, name: "연습 문제2", val: _item[16] ? _item[16] : ""}, 
                        {id: 4, name: "실전 테스트1", val: _item[17] ? _item[17] : ""}, 
                        {id: 5, name: "실전 테스트2", val: _item[18] ? _item[18] : ""}, 
                        {id: 6, name: "실전 테스트3", val: _item[19] ? _item[19] : ""}, 
                        {id: 7, name: "문법 타이틀", val: _item[20] ? _item[20] : ""}, 
                        {id: 8, name: "개념 학습", val: _item[21] ? _item[21] : ""}, 
                        {id: 9, name: "연습 문제1", val: _item[22] ? _item[22] : ""}, 
                        {id: 10, name: "연습 문제2", val: _item[23] ? _item[23] : ""}, 
                        {id: 11, name: "실전 테스트1", val: _item[24] ? _item[24] : ""}, 
                        {id: 12, name: "실전 테스트2", val: _item[25] ? _item[25] : ""}, 
                        {id: 13, name: "실전 테스트3", val: _item[26] ? _item[26] : ""}, 
                        {id: 14, name: "단원 테스트1", val: _item[27] ? _item[27] : ""}, 
                        {id: 15, name: "단원 테스트2", val: _item[28] ? _item[28] : ""}, 
                        {id: 16, name: "단원 테스트3", val: _item[29] ? _item[29] : ""}, 
                    ];                                     
                    tempListString_Val = JSON.stringify(arrGrammarTemplate);

                    creator_Val = _item[30];
                    is_active_Val = Number(_item[32]) === 1 ? true : false;
                    is_publicOpen_Val = Number(_item[33]) === 1 ? true : false;

                    const _data = {
                        "id": id_Val,
                        "brand_id": brand_id_Val,
                        "type_1": type_1_Val,
                        "name": name_Val, 
                        "is_active": is_active_Val, 
                        "is_publicOpen": is_publicOpen_Val, 
            
                        "creator": id_Val ? undefined : creator_Val,
                        
                        "updater": id_Val ? creator_Val : undefined,
                        
                        "description": description_Val,
                        
                        "curriBook": curriBook_Val,
                        "curriUnit1": curriUnit1_Val,
                        "curriUnit2": curriUnit2_Val,
                        "curriUnit3": curriUnit3_Val,
                        "curriUnit4": curriUnit4_Val,
                        
                        "metaMap": listMetaMap, 
                                
                        "tempListString" : tempListString_Val,
            
                        "evaluationArea2String" : evaluationArea2String_Val,
                    }
        
                    logger.log("_data", _data);
                    await _onSave(_data, id_Val ? "update" : "insert");                    
                }                
            };
            setFileUploading(false);
            props.OnClose();
        }
    }

    async function onExcelProccessing(e: ProgressEvent<FileReader>) {

        if(e.target) {

            setFileUploading(true);

            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            let id_Val = undefined;
            let audioKey_Val = undefined;
            let videoKey_Val = undefined;
            let brand_id_Val = undefined;
            let children_Val = undefined;
            let creator_Val = undefined;
            let curriBook_Val = undefined;
            let curriUnit1_Val = undefined;
            let curriUnit2_Val = undefined;
            let curriUnit3_Val = undefined;
            let curriUnit4_Val = undefined;
            let description_Val = undefined;
            let evaluationArea1String_Val = undefined;
            let evaluationArea2String_Val = undefined;
            let evaluationArea3String_Val = undefined;
            let name_Val = undefined;
            let type_1_Val = undefined;
            let is_active_Val = undefined;
            let is_publicOpen_Val = undefined;
            // let tempDepth_Val = undefined;
            let tempListString_Val = undefined;
            let textKey_Val : any[] = [];
            
            const listMetaMap : IMeta[] = [];
            const tempDiv2EV1List : ITempDiv2EV1[] = [];
            const tempDiv2EV3List : ITempDiv2EV3[] = [];
            const tempDiv : IMeta[] = [];
            // const grammarTemplateList : IGrammarTemplate[] = [];

            const list1 = await getMetaList("1", "evaluationArea");
            // const list2 = await getMetaList("1", "contentsItem");
            const list3 = await getMetaList("1", "evaluationArea3");
            const templateDiv = await util.getMetaList("1", "templateDiv");

            for (let i = 4; i < dataParse.length; i++) {
                const item = dataParse[i];

                const _item = item as string[];

                if(_item.length > 0) {

                    type_1_Val = _item[11];
                    if(type_1_Val === "Grammar") {
                        await onExcelProccessing_Grammar(e);
                        return;
                    }

                    if(i === 4) {
                        
                        id_Val = _item[1] && Number(_item[1]) > 0 ? _item[1] : undefined;
                        brand_id_Val = _item[0];
                        name_Val = _item[2];
                        description_Val = _item[3];
                        listMetaMap.push({id: Number(_item[4])} as IMeta);
                        listMetaMap.push({id: Number(_item[5])} as IMeta);                
                        curriBook_Val = _item[6];
                        curriUnit1_Val = _item[7];
                        curriUnit2_Val = _item[8];
                        curriUnit3_Val = _item[9];
                        curriUnit4_Val = _item[10];
                        type_1_Val = _item[11];
                        const _templateDiv = templateDiv.find((meta) => meta.val === String(_item[11]));
                        if(_templateDiv && _templateDiv.code) {
                            listMetaMap.push(_templateDiv);
                            const list = await getDescendantsList(_templateDiv?.code);
                            list.map((item) => {
                                tempDiv.push(item);
                            });
                        }
                
                        if(type_1_Val === "Listening Comprehension" || type_1_Val === "Reading Comprehension") {
                            audioKey_Val = _item[14] && Number(_item[14]) > 0 ? {id: Number(_item[14])} as IMeta : undefined;
                            videoKey_Val = _item[15] && Number(_item[15]) > 0 ? {id: Number(_item[15])} as IMeta : undefined;    
                            creator_Val = _item[16];
                            is_active_Val = Number(_item[18]) === 1 ? true : false;
                            is_publicOpen_Val = Number(_item[19]) === 1 ? true : false;
                        } else if(type_1_Val === "Voca") {
                            const arr = _item[14].split(",");
                            arr.map((item) => {
                                textKey_Val.push({id: Number(item)} as IText);
                            });
                            creator_Val = _item[15];
                            is_active_Val = Number(_item[17]) === 1 ? true : false;
                            is_publicOpen_Val = Number(_item[18]) === 1 ? true : false;
                        } else if(type_1_Val === "Grammar") {
                            creator_Val = _item[30];
                            is_active_Val = Number(_item[32]) === 1 ? true : false;
                            is_publicOpen_Val = Number(_item[33]) === 1 ? true : false;

                            evaluationArea2String_Val = _item[12];
                            // tempDepth_Val = _item[13];

                        } else {
                            creator_Val = _item[12];
                            is_active_Val = Number(_item[14]) === 1 ? true : false;
                            is_publicOpen_Val = Number(_item[15]) === 1 ? true : false;
                        }

                    }

                    if(type_1_Val === "Grammar") {
                        // tempListString

                        const arrGrammarTemplate : IGrammarTemplate[] = [
                            {id: 0, name: "문법 타이틀", val: _item[13] ? _item[13] : ""}, 
                            {id: 1, name: "개념 학습", val: _item[14] ? _item[14] : ""}, 
                            {id: 2, name: "연습 문제1", val: _item[15] ? _item[15] : ""}, 
                            {id: 3, name: "연습 문제2", val: _item[16] ? _item[16] : ""}, 
                            {id: 4, name: "실전 테스트1", val: _item[17] ? _item[17] : ""}, 
                            {id: 5, name: "실전 테스트2", val: _item[18] ? _item[18] : ""}, 
                            {id: 6, name: "실전 테스트3", val: _item[19] ? _item[19] : ""}, 
                            {id: 7, name: "문법 타이틀", val: _item[20] ? _item[20] : ""}, 
                            {id: 8, name: "개념 학습", val: _item[21] ? _item[21] : ""}, 
                            {id: 9, name: "연습 문제1", val: _item[22] ? _item[22] : ""}, 
                            {id: 10, name: "연습 문제2", val: _item[23] ? _item[23] : ""}, 
                            {id: 11, name: "실전 테스트1", val: _item[24] ? _item[24] : ""}, 
                            {id: 12, name: "실전 테스트2", val: _item[25] ? _item[25] : ""}, 
                            {id: 13, name: "실전 테스트3", val: _item[26] ? _item[26] : ""}, 
                            {id: 14, name: "단원 테스트1", val: _item[27] ? _item[27] : ""}, 
                            {id: 15, name: "단원 테스트2", val: _item[28] ? _item[28] : ""}, 
                            {id: 16, name: "단원 테스트3", val: _item[29] ? _item[29] : ""}, 
                        ];
                                         
                        tempListString_Val = JSON.stringify(arrGrammarTemplate);

                        // const arr = _item[15].split("-");
                        // const _grammarTemplate : IGrammarTemplate = {
                        //     id: grammarTemplateList.length+1,
                        //     name: "",
                        //     val: _item[14],
                        //     // depth: arr.length,
                        //     // parent: _item[15].substring(0, _item[15].lastIndexOf("-")),
                        //     // code: _item[15],
                        //     // articles: _item[16],
                        // }
                        // grammarTemplateList.push(_grammarTemplate);

                    } else {
                        if(children_Val === undefined) {
                            children_Val = _item[12];
                        } else {
                            children_Val = children_Val + "," + _item[12];
                        }
    
                        const tempDiv2Meta = tempDiv.find((meta) => meta.val === _item[12]);
                        if(tempDiv2Meta) {                
    
                            if(type_1_Val === "Listening Comprehension" || type_1_Val === "Reading Comprehension") {
                                const arrEV1 = _item[13].split(",");
                                const tempDiv2EV1 : ITempDiv2EV1 = {
                                    tempDiv2: {"id": tempDiv2Meta.id, "val": tempDiv2Meta.val} as IMeta,
                                    EV1_1: arrEV1.length > 0 ? {"id": list1.find((meta) => meta.val === arrEV1[0])?.id, "val": list1.find((meta) => meta.val === arrEV1[0])?.val} as IMeta : undefined,
                                    EV1_2: arrEV1.length > 1 ? {"id": list1.find((meta) => meta.val === arrEV1[1])?.id, "val": list1.find((meta) => meta.val === arrEV1[1])?.val} as IMeta : undefined,
                                    EV1_3: arrEV1.length > 2 ? {"id": list1.find((meta) => meta.val === arrEV1[2])?.id, "val": list1.find((meta) => meta.val === arrEV1[2])?.val} as IMeta : undefined,
                                    EV1_4: arrEV1.length > 3 ? {"id": list1.find((meta) => meta.val === arrEV1[3])?.id, "val": list1.find((meta) => meta.val === arrEV1[3])?.val} as IMeta : undefined,
                                    EV1_5: arrEV1.length > 4 ? {"id": list1.find((meta) => meta.val === arrEV1[4])?.id, "val": list1.find((meta) => meta.val === arrEV1[4])?.val} as IMeta : undefined,
                                    EV1_6: arrEV1.length > 5 ? {"id": list1.find((meta) => meta.val === arrEV1[5])?.id, "val": list1.find((meta) => meta.val === arrEV1[5])?.val} as IMeta : undefined,
                                }    
                                listMetaMap.push({"id": tempDiv2Meta.id} as IMeta);
                                tempDiv2EV1List.push(tempDiv2EV1);
                            } else if(type_1_Val === "Voca") {
                                const EV3 = _item[13];
                                const tempDiv2EV3 : ITempDiv2EV3 = {
                                    tempDiv2: {"id": tempDiv2Meta.id, "val": tempDiv2Meta.val} as IMeta,
                                    EV3: {"id": list3.find((meta) => meta.val === EV3)?.id , "val": list3.find((meta) => meta.val === EV3)?.val} as IMeta,
                                }    
                                listMetaMap.push({"id": tempDiv2Meta.id} as IMeta);
                                tempDiv2EV3List.push(tempDiv2EV3);
                            } else {
                            }
                        }       
                    }
                }                
            };
            
            if(tempDiv2EV1List.length > 0) {
                evaluationArea1String_Val = JSON.stringify(tempDiv2EV1List);
            }
            if(tempDiv2EV3List.length > 0) {
                evaluationArea3String_Val = JSON.stringify(tempDiv2EV3List);
            }
            // if(grammarTemplateList.length > 0) {
            //     tempListString_Val = JSON.stringify(grammarTemplateList);
            // }

            const _data = {
                "id": id_Val,
                "brand_id": brand_id_Val,
                "type_1": type_1_Val,
                "name": name_Val, 
                "is_active": is_active_Val, 
                "is_publicOpen": is_publicOpen_Val, 
    
                "creator": id_Val ? undefined : creator_Val,
                
                "updater": id_Val ? creator_Val : undefined,
                
                "description": description_Val,
                
                "curriBook": curriBook_Val,
                "curriUnit1": curriUnit1_Val,
                "curriUnit2": curriUnit2_Val,
                "curriUnit3": curriUnit3_Val,
                "curriUnit4": curriUnit4_Val,
                
                "metaMap": listMetaMap, 
                        
                "children" : children_Val,
    
                "audioKey": audioKey_Val,
                "videoKey": videoKey_Val,
    
                "textKey": textKey_Val.length > 0 ? textKey_Val : undefined,
    
                // "tempDepth" : tempDepth_Val,
    
                "tempListString" : tempListString_Val,
    
                "evaluationArea1String" : evaluationArea1String_Val,
                "evaluationArea2String" : evaluationArea2String_Val,
                "evaluationArea3String" : evaluationArea3String_Val,
            }

            logger.log("_data", _data);
            await _onSave(_data, id_Val ? "update" : "insert");

            setFileUploading(false);

            props.OnClose();
        }

    }

    async function _onSave(data: any, type: string) {
        const result = await util.engtempForSave(data, type);
        // logger.log("_onSave", data, result);
    }

    async function onFileUploadForSave() {

        if(!fileContent) {
            props.showModal("", "엑셀 파일을 등록해주세요.");
            return;
        }

        var reader = new FileReader();
        reader.onload = (e) => onExcelProccessing(e);
        reader.readAsBinaryString(fileContent.file)

        // const brandId = props.brand.id;
        // const filepath = "upload/"+brandId+"/"+util.getDate().replaceAll(".", "")+"/";

        // logger.log("onFileUploadForSave started!!!");      

        // setFileUploading(true);
    
        // const result = await util.UploadFile(fileContent.file!, filepath, "EngTemp");
        // logger.log("onFileUploadForSave", result);
        // logger.log("onFileUploadForSave", fileContent, result);

        // logger.log("onFileUploadForSave finished!!!");  
        
        // setFileUploading(false);

        // props.showModal("", "등록이 완료 되었습니다.");
        // props.OnClose();                

    }
    
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  
        logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const _url = e.target.files![0];
        const _content : ILibraryFile = {
            type: _url.type,
            file: _url,
            url: _url.name,
            thumbnail: _url.name,
            isDelete: false,
            isUploaded: false
        }
        logger.log("handleChange", _content);
    
        setFileContent(_content);
    }

    function onDownload(type : string) {

        let url = "";
        if(type === "영어교과템") {
            url = common.S3Server + "upload/1/20240201/%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%8B%E1%85%A5%E1%84%80%E1%85%AD%E1%84%80%E1%85%AA%E1%84%90%E1%85%A6%E1%86%B7.xlsx";
        }

        window.open(url, "_blank", "noopener, noreferrer");    
    }

    return (
        <div>

            <div>

                <h4>일괄 등록</h4>

                {/* <!-- table --> */}
				<table>
					<caption className="sr-only">이미지 관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row" className="w-56">샘플 파일 다운로드</th>
							<td className="text-left">
								<div>
									<button type="button" className="btn-white-s" onClick={() => onDownload("영어교과템")}><span className="file-down">Download</span></button>
								</div>
							</td>
						</tr>
						<tr>
							<th scope="row"><label htmlFor="file-input" className="mr-0">파일 찾기</label></th>
							<td className="text-left">
								<label htmlFor="file-input" className="sr-only">파일찾기</label>
								{/* <input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file"/> */}
								<input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file" onChange={(e) => handleChange(e)}/>
							</td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
				
				<div className="relative mt-5">
					<div className="flex justify-between">
						<button type="button" className="btn-white" onClick={OnClose}>목록</button>
						<button type="button" className="btn-sky" onClick={onFileUploadForSave}>등록</button>
					</div>
				</div>

            </div>

            {fileUploading && <>
                <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="dim">
                        <CircularProgress size={40} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: `${-40/2}px`, marginLeft: `${-40/2}px` }} />
                    </div>
                </div>
            </>}

        </div>
    );
}

export default LibraryEngTempBulkRegister;