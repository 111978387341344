import { MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { count } from "console";
import MetaAddEdit from "./MetaAddEdit";
import { CurriInfo, IBrand, ICurriInfo, IMenu, IMeta, MetaMenuInfo, headers, isDev } from "../common";
import '../common.scss';
import { logger } from '../logger';
import MetaStudyMap from "./MetaStudyMap";
import MetaContentsItem from "./MetaContentsItem";
import MetaBulkRegister from "./MetaBulkRegister";
import MetaAchievementStandard from "./MetaAchievementStandard";
import MetaMaterial from "./MetaMaterial";
import MetaCommunication from "./MetaCommunication";
import MetaLanguageFormat from "./MetaLanguageFormat";
import MetaStudyMapEng from "./MetaStudyMapEng";

import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

interface IMetaManager {
    view: boolean;
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

export interface ISearchData {
    school: string;
    subject: string;
    unit: string;
    keyword: string;
}

function MetaManager(props: IMetaManager) {

    const [metaList, setMetaList] = useState([] as IMeta[]);
    const [viewList, setViewList] = useState(false);
    const [metaHList, setMetaHList] = useState([] as IMeta[]);
    const [viewHList, setViewHList] = useState(false);
    const [viewSearch, setViewSearch] = useState(false);

    const [currItem, setCurrItem] = useState(null as any);
    const [parentItem, setParentItem] = useState(null as any);
    // const [selectedBook, setSelectedBook] = useState(null as any);
    const [searchData, setSearchData] = useState(null as any);

    const [isEditing, setIsEditing] = useState(false);
    const [isBulkEditing, setIsBulkEditing] = useState(false);

    const [title, setTitle] = useState("");

    const [viewStudyMap, setViewStudyMap] = useState(false);
    const [viewContentsItem, setViewContentsItem] = useState(false);
    const [viewAchievementStandard, setViewAchievementStandard] = useState(false);
    const [viewMaterial, setViewMaterial] = useState(false);
    const [viewCommunication, setViewCommunication] = useState(false);
    const [viewLanguageFormat, setViewLanguageFormat] = useState(false);
    const [viewStudyMapEng, setViewStudyMapEng] = useState(false);

    const [selectedMeta, setSelectedMeta] = useState(null as any);

    const [bulkUpdate, setBulkUpdate] = useState(false);

    const [metaName, setMetaName] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaType, setMetaType] = useState("");

    const [selectedItemId, setSelectedItemId] = useState(null as any);

    let prevView = false;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {    
            setViewList(false);
            setIsEditing(false);
            setViewHList(false);
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.view && !prevView) {
        }
        prevView = props.view;
    }, [props.view])

    useEffect(() => {

        logger.log("viewHList", viewHList, searchData);

        if(viewHList) {
        } else {
            if(searchData) {
                const MM_School = document.getElementById("MM_School") as HTMLSelectElement;
                if(MM_School) {
                    MM_School.value = (searchData as ISearchData).school;
                }
                const MM_Unit = document.getElementById("MM_Unit") as HTMLSelectElement;
                if(MM_Unit) {
                    MM_Unit.value = (searchData as ISearchData).unit;
                }
                const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement);
                if(searchKeyword) {
                    (document.getElementById('searchKeyword') as HTMLInputElement).value = (searchData as ISearchData).keyword;
                }    
                const MM_Subject = document.getElementById("MM_Subject") as HTMLSelectElement;
                if(MM_Subject) {
                    MM_Subject.value = (searchData as ISearchData).subject;
                }
            }
        }
    }, [viewHList])

    useEffect(() => {

        logger.log("isEditing", isEditing, searchData);

        if(isEditing) {
        } else {
            if(searchData) {
                const MM_School = document.getElementById("MM_School") as HTMLSelectElement;
                if(MM_School) {
                    MM_School.value = (searchData as ISearchData).school;
                }
                const MM_Unit = document.getElementById("MM_Unit") as HTMLSelectElement;
                if(MM_Unit) {
                    MM_Unit.value = (searchData as ISearchData).unit;
                }
                const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement);
                if(searchKeyword) {
                    (document.getElementById('searchKeyword') as HTMLInputElement).value = (searchData as ISearchData).keyword;
                }    
                const MM_Subject = document.getElementById("MM_Subject") as HTMLSelectElement;
                if(MM_Subject) {
                    MM_Subject.value = (searchData as ISearchData).subject;
                }
            }
        }
    }, [isEditing])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu");
            changeMenu();
        }
    }, [props.menu])

    useEffect(() => {
        if(props.brand) {
        }
    }, [props.brand])

    useEffect(() => {
        if(metaHList && metaHList.length > 0) {
            //정합성 체크로직
            // testParent(metaHList);
        }
    }, [metaHList])

    async function testParent(metalist: IMeta[]) {

        for (let i = 0; i < metalist.length; i++) {
            const meta = metalist[i];
            let _pcode = "";
            const arr = meta.code.split("-");
            for (let i = 0; i < arr.length-1; i++) {
                if(_pcode === "")
                    _pcode = arr[i];
                else 
                    _pcode = _pcode + "-" + arr[i];
            }
            
            const parent = await util.getMetaByCode(_pcode);
            if(parent) {
                if(meta.parent_id !== (parent as IMeta).id) {
                    logger.log("testParent", meta, (parent as IMeta));
                    alert("정합성 오류!!! " + meta.name + "(" + meta.id + ")");
                } else {
                    logger.log("testParent ok", i, metalist.length);
                }
            }
        }

        logger.log("정합성 테스트 완료!!!");
    }

    const changeMenu = () => {

        const menu = util.getCurrentMenu();
        logger.log("menu", menu);

        setViewList(false);

        if(menu) {

            const _metaName = MetaMenuInfo[menu.menu2].name;
            const _metaTitle = MetaMenuInfo[menu.menu2].title;
            const _metaType = MetaMenuInfo[menu.menu2].type; 

            setMetaName(_metaName);
            setMetaTitle(_metaTitle);
            setMetaType(_metaType ? _metaType : "");

            getMetaList("1", _metaName);

            setBulkUpdate(false);
            if(_metaName === "studyMap") {
                // const currBrand = util.getCurrentBrand();
                // if(currBrand && currBrand.code === "VAMT01") {
                //     setBulkUpdate(true);
                // }
    
                setBulkUpdate(true);
    
            }
            if(_metaName === "curriBook") {
                setBulkUpdate(true);
            }
                
        } else {
            
        }

        // if(_name === "teachingCategory") {
        //     const brand = util.getCurrentBrand();
        //     if(brand && brand.code === "VSTB00") {
        //         _title = "도서분류";
        //     } 
        // }
        
        // setTitle(_title);
        setIsEditing(false);
        setViewStudyMap(false);
        setViewContentsItem(false);
        setViewAchievementStandard(false);
        setViewMaterial(false);
        setViewCommunication(false);
        setViewLanguageFormat(false);
        setViewStudyMapEng(false);

        // if(menu.menu2 === 17) {
        //     const data = {
        //         code : "templateDiv",
        //     }
        //     getDescendantsList(data as IMeta);
        // }

    }

    async function getDescendantsList(meta: IMeta) {

        props.showLoading(true);

        const list = await util.getDescendantsList(meta.code+"-");
        const _list = list.filter((item) => item.code !== meta.code);

        for (let i = 0; i < _list.length; i++) {
            const item = _list[i];
            item.isOpened = true;
            item.hasChild = _list.find((_item) => _item.parent_id === item.id) ? true : false;
        }

        logger.log("getDescendantsList", _list);  

        setMetaHList(_list);
        setViewHList(true);

        props.showLoading(false);
    }

    async function getMetaList(depth: string, name: string) {

        props.showLoading(true);

        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	

        const activeList = _list.filter((meta) => meta.is_active);
        const nonActiveList = _list.filter((meta) => !meta.is_active);

        setMetaList(activeList.concat(nonActiveList));
        setViewList(true);
        setViewHList(false);

        props.showLoading(false);
    }

    function onAddTopMeta(): void {
        setParentItem(null);
        setCurrItem(null);
        setIsEditing(true);
    }

    function onAddMetaToCurrent(): void {
        logger.log("onAddMetaToCurrent setParentItem", selectedMeta);
        setParentItem(selectedMeta);
        setCurrItem(null);
        setIsEditing(true);
    }
    
    function onAddChildMeta(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, item: IMeta): void {
        e.stopPropagation();

        logger.log("onAddChildMeta setParentItem", item);
        setParentItem(item);  
        setCurrItem(null);
        setIsEditing(true);  
        setSelectedItemId("id"+item.id);
    }

    function onEditMeta(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, item: IMeta): void {
        e.stopPropagation();

        if(viewHList) {            
            
            logger.log("onEditMeta item", item);

            let _pcode = "";
            const arr = item.code.split("-");
            for (let i = 0; i < arr.length-1; i++) {
                if(_pcode === "")
                    _pcode = arr[i];
                else 
                    _pcode = _pcode + "-" + arr[i];
            }
            // const _pcode = item.code.substring(0, item.code.length-5);
            logger.log("onEditMeta _pcode", _pcode, selectedMeta);

            let _parentItem = metaHList.find((item) => item.code === _pcode);
            if(!_parentItem) {
                if(metaName === "curriBook") {
                    _parentItem = (curriInfo?.curriBook as IMeta[]).find((item) => item.code === _pcode);
                    if(!_parentItem) {
                        logger.log("onEditMeta setCurrItem", _parentItem, _pcode);
                        alert("parentItem 가져오기 실패!!!");
                        return;
                    }    
                } else if(metaName === "templateDiv") {
                    _parentItem = selectedMeta;
                }
            }
            setParentItem(_parentItem);
            logger.log("onEditMeta setCurrItem", item, _parentItem);
        } else {
            setParentItem(null);
        }
        setCurrItem(item);
        setIsEditing(true);  
        setSelectedItemId("id"+item.id);
    }

    function onCloseEdit(isEdited: boolean, from: string): void {
        setIsEditing(false);
        if(isEdited) {            
            if(from === "") {
                const _name = metaName;
                getMetaList("1", _name);
            } else if(from === "H") {
                getDescendantsList(selectedMeta);
            } 
        }

        setSelectedItemId(null);

        setTimeout(() => {
            const element = document.getElementById(selectedItemId);
            // logger.log("onCloseEdit", currItem, "id"+currItem.id, element);
            element?.scrollIntoView({
              behavior: 'auto',
              block: "start",
              inline: "nearest",
            }); 

            setTimeout(() => {
                const position = window.pageYOffset - 0; //84
                logger.log("pageOffset", window.pageXOffset, position);
                window.scrollTo({
                    left: window.pageXOffset,
                    top: position,
                });
            }, 0);
        }, 0);

    }

    function onClickName(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, item: common.IMeta): void {
        const _type = metaType;
        if(_type !== "H") {
            return;
        }
        if(metaName === "curriBook") {
            setSelectedMeta(item);
            getDescendantsList(item);
        } else if(metaName === "studyMap") {
            setSelectedMeta(item);
            const currBrand = util.getCurrentBrand();
            if(currBrand && currBrand.code === "VAET01") {
                setViewStudyMapEng(true);
            } else {
                setViewStudyMap(true);
            }
        } else if(metaName === "templateDiv") {
            setSelectedMeta(item);
            getDescendantsList(item);
        } else if(metaName === "contentsItem") {
            setSelectedMeta(item);
            setViewContentsItem(true);
        } else if(metaName === "achievementStandard") {
            setSelectedMeta(item);
            setViewAchievementStandard(true);
        } else if(metaName === "material") {
            setSelectedMeta(item);
            setViewMaterial(true);
        } else if(metaName === "communication") {
            setSelectedMeta(item);
            setViewCommunication(true);
        } else if(metaName === "languageFormat") {
            setSelectedMeta(item);
            setViewLanguageFormat(true);
        }

        //|| metaName === "material" || metaName === "communication" || metaName === "languageFormat")
    }

    function onSearch(): void {
        const MM_School = document.getElementById("MM_School") as HTMLSelectElement;
        const MM_Unit = document.getElementById("MM_Unit") as HTMLSelectElement;
        const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement).value
        const MM_Subject = document.getElementById("MM_Subject") as HTMLSelectElement;

        const data : ISearchData = {
            school: MM_School ? MM_School.value : "",
            unit: MM_Unit.value,
            keyword: searchKeyword,
            subject: MM_Subject ? MM_Subject.value : "",
        }
        logger.log("onSearch", data);
        setSearchData(data);
        _onSearch(data);            
    }

    async function _onSearch(data: ISearchData) {

        const currBrand = util.getCurrentBrand();

        if(data.school === "" && data.subject === "" && data.keyword === "") {
            if(metaName === "studyMap") {
                getMetaList("1", "studyMap");
            } else {
                getMetaList("1", "curriBook");
            }
        } else {
            props.showLoading(true);
            if(metaName === "studyMap") {
                const list = await util.searchStudyMap(data);
                const newList = list.filter((meta) => meta.brand_id === currBrand?.id);
                logger.log("matchSubject", newList);
                setMetaList(newList);        
            } else {
                const list = await util.searchCurriBook(data);
                const newList = list.filter((meta) => meta.brand_id === currBrand?.id);
                logger.log("matchSchool", newList);
                setMetaList(newList);        
            }
            props.showLoading(false);
        }
    }

    function onChangeTree(item: IMeta) {
        const _metaHList = JSON.parse(JSON.stringify(metaHList));
        const _item = (_metaHList as IMeta[]).find((meta) => meta.id === item.id);
        if(_item) {            
            _item.isOpened = !item.isOpened;
            setMetaHList(_metaHList);
        }                    
    }

    let parents : IMeta[] = [];
    function getParents(item: IMeta, start: boolean) {
        if(start) {
            parents = [];
        }
        const parent = metaHList.find((meta) => meta.id === item.parent_id);
        if(parent) {
            parents.push(parent);
            getParents(parent, false);
        }
    }

    return (
    <div>

        {/* <Latex>{"$\\notin$"}</Latex> */}

        <div className="top-title">
            <h3>{metaTitle + "(" + metaName + ")"}</h3>
        </div>

        {/* <!-- 검색영역 --> */}
        {!viewHList && !isEditing && !isBulkEditing && !viewStudyMap && !viewStudyMapEng && (metaName === "curriBook" || metaName === "studyMap") &&
        <div className="px-8 py-8 mb-8 border border-gray-300 rounded-xl">
                <div className="text-right">
                    <fieldset>
                    {/* <label htmlFor="sel-title">{metaName === "curriBook" ? "학교급" : "교과목"}</label> */}
                    <label htmlFor="sel-title">{"학교급"}</label>
                        {metaName === "curriBook" &&
                        <select id="MM_School">
                            <option value="">전체</option>
                            {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>              
                            )})}
                        </select>}
                        {metaName === "studyMap" &&
                        <select id="MM_School">
                            <option value="">전체</option>
                            {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>              
                            )})}
                        </select>}

                        {/* {metaName === "studyMap" &&
                        <select id="MM_Subject">
                            <option value="">전체</option>
                            {curriInfo && curriInfo.curriSubject && (curriInfo.curriSubject as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>              
                            )})}
                        </select>} */}
                        <label htmlFor="sel-meta" className="ml-3">메타 값</label>
                        <select id="MM_Unit">
                            <option value="">전체</option>
                            <option value="curriUnit1">1Depth</option>
                            <option value="curriUnit2">2Depth</option>
                            <option value="curriUnit3">3Depth</option>
                            <option value="curriUnit4">4Depth</option>
                            <option value="curriUnit5">5Depth</option>
                        </select>
                        <input type="text" name="searchKeyword" id="searchKeyword" className="w-[300px] ml-3 mr-2" onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                onSearch();
                            }
                        }} />
                        <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                            <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                        </button>
                    </fieldset>
                </div>
        </div>}
        {/* <!-- .//검색영역 --> */}

        {!viewHList && !isEditing && !isBulkEditing && viewList && !viewStudyMap && !viewContentsItem && !viewAchievementStandard && !viewMaterial && !viewCommunication && !viewLanguageFormat && !viewStudyMapEng &&

        <div>
            <table>
                <caption className="sr-only">{title}</caption>
                <thead>
                    <tr>
                        <th scope="col" className="pl-4 pr-3" style={{width:"80px"}}>번호</th>
                        <th scope="col" className="px-3">메타 값</th>
                        <th scope="col" className="px-3">Key</th>
                        <th scope="col" className="px-3">코드</th>
                        <th scope="col" className="px-3">설명</th>
                        <th scope="col" className="px-3" style={{width:"80px"}}>사용</th>
                        <th scope="col" className="px-3" style={{width:"100px"}}>등록자​</th>
                        <th scope="col" className="pl-3 pr-4" style={{width:"100px"}}>상세보기</th>
                    </tr>
                </thead>

                <tbody>
                    {viewList && metaList && (metaList as IMeta[]).length === 0 &&
                    <tr>
                        <td colSpan={7}>등록된 메타 정보가 없습니다.</td>
                    </tr>}

                    {metaList && (metaList as IMeta[]).map((item, idx) => {
                        const type = metaType ? metaType : "";
                        const bLinkCls = item.name === "curriBook" || item.name === "studyMap" ? true : false;
                        return (
                            <tr key={idx} onClick={(e) => onClickName(e, item)} style={{cursor: type === "H" ? "pointer" : "default", backgroundColor: item.is_active ? "#ffffff" : "#f0f0f0"}}>
                                <td>{metaList.length-idx}</td>
                                {bLinkCls && <td><a href="#" className="link">{item.val}</a></td>}
                                {!bLinkCls && <td>{item.val}</td>}
                                <td className={"text-center"}>{item.id}</td>
                                <td>{item.code}</td>
                                <td className="text-left"><p className="xl:w-80 truncate">{item.description}</p></td>
                                <td>{item.is_active ? "사용" : "미사용"}</td>
                                <td className="leading-4">{item.creator}</td>
                                <td>
                                    <button type="button" className="btn-white-s" onClick={(e) => onEditMeta(e, item)}>보기</button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <div className="relative mt-5 mb-5">
                <div className="flex">
                    <div className="absolute top-0 right-0 text-right">
                        {isDev && !viewHList && bulkUpdate && 
                        <button type="button" className="btn-sky mr-2" onClick={() => {
                            setIsBulkEditing(true);
                        }}>일괄등록</button>}
                        <button type="button" className="btn-sky" onClick={onAddTopMeta}>등록</button>
                    </div>
                </div>
            </div>

        </div>}

        {viewHList && !isEditing && !isBulkEditing && viewList && !viewStudyMap && !viewContentsItem && !viewAchievementStandard && !viewMaterial && !viewCommunication && !viewLanguageFormat && !viewStudyMapEng &&
        <div style={{width: "91rem", paddingRight: "3rem"}}>

            <div style={{display: isEditing ? "none" : ""}}>
                <table>
                    <caption className="sr-only">{title}</caption>
                    <thead>
                        <tr>
                            <th scope="col" className="" style={{width: metaName === "curriBook" ? "40rem" : "20rem"}}>메타 값</th>
                            <th scope="col" className="" style={{width: "5rem"}}>Key</th>
                            <th scope="col" className="" style={{width: "17rem"}}>코드</th>
                            <th scope="col" className="" style={{width:"5rem"}}>사용</th>
                            <th scope="col" className="" style={{width:"7rem"}}>추가</th>
                            <th scope="col" className="" style={{width:"7rem"}}>등록자​</th>
                            <th scope="col" className="" style={{width:"7rem"}}>상세보기</th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div className="overflow-y-auto index-scroll" style={{display: isEditing ? "none" : "", height: "42rem"}}>

                <table>

                    {/* <caption className="sr-only">{title}</caption>
                    <thead>
                        <tr>
                            <th scope="col" className="px-3">메타 값</th>
                            <th scope="col" className="px-3">Key</th>
                            <th scope="col" className="px-3">코드</th>
                            <th scope="col" className="px-3" style={{width:"80px"}}>사용</th>
                            <th scope="col" className="px-3" style={{width:"80px"}}>추가</th>
                            <th scope="col" className="px-3" style={{width:"100px"}}>등록자​</th>
                            <th scope="col" className="pl-3 pr-4" style={{width:"100px"}}>상세보기</th>
                        </tr>
                    </thead> */}

                    <tbody>

                        <tr>
                            <td className="" style={{width: metaName === "curriBook" ? "40rem" : "20rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width: "5rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width: "17rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"5rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"7rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"7rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"7rem", height: 0, padding: 0}}></td>
                        </tr>

                        {metaHList && (metaHList as IMeta[]).length === 0 &&
                        <tr>
                            <td colSpan={7}>등록된 메타 정보가 없습니다.</td>
                        </tr>}

                        {metaHList && (metaHList as IMeta[]).map((item, idx) => {
                            let _code = item.code;
                            const arrCode = item.code.split("-");
                            // _code = arrCode[arrCode.length-1];
                            _code = _code.replace(arrCode[0]+"-", "");

                            let clsName = "";
                            if(item.name === "curriBook") {
                                clsName = "";
                            } else if(item.name === "curriUnit1") {
                                clsName = "pl-5";
                            } else if(item.name === "curriUnit2") {
                                clsName = "pl-10";
                            } else if(item.name === "curriUnit3") {
                                clsName = "pl-15";
                            } else if(item.name === "curriUnit4") {
                                clsName = "pl-20";
                            } else if(item.name === "curriUnit5") {
                                clsName = "pl-25";
                            } else if(item.name === "curriUnit6") {
                                clsName = "pl-30";
                            }
                            
                            const maxDepth = selectedMeta.max_depth ? selectedMeta.max_depth : 5;
                            const bAdd = maxDepth >= item.depth;

                            let view = true;                
                            getParents(item, true);
                            for (let i = 0; i < parents.length; i++) {
                                const parent = parents[i];    
                                if(!parent.isOpened) {
                                    view = false;
                                    break;
                                }
                            }
                            // logger.log("=>", item.id, item.code, view);
    
                        return (
                            <tr key={idx} id={"id"+item.id} style={{display: view ? "" : "none", backgroundColor: item.is_active ? "#ffffff" : "#f0f0f0"}}>
                            {/* <tr key={idx} style={{backgroundColor: item.is_active ? "#ffffff" : "#f0f0f0"}}> */}
                                {/* <td className="w-20">{metaHList.length-idx}</td> */}
                                <td className="text-left">                                
                                    <span className={clsName} onClick={() => {
                                            if(item.hasChild) {
                                                onChangeTree(item);
                                            }
                                    }} style={{cursor: "pointer"}} >
                                        <button className="mr-2" type="button" style={{width: "1rem"}} >
                                            {item.hasChild && item.isOpened &&
                                            <img src="./images/arrow-up.png" alt="arrow-up" style={{width: "0.95rem"}} />}
                                            {item.hasChild && !item.isOpened &&
                                            <img src="./images/arrow-right.png" alt="arrow-right" style={{width: "0.75rem"}} />}
                                        </button>
                                        {item.val}{/* <Latex delimiters={latex_delimiters} macros={latex_macros}>{name}</Latex> */}
                                    </span>
                                </td>
                                {/* <td className="text-left"><p className={clsName}>{item.val}</p></td> */}
                                <td className={"text-center"}>{item.id}</td>
                                <td className="text-left">{_code}</td>
                                <td>{item.is_active ? "사용" : "미사용"}</td>
                                <td className="w-24">
                                    {bAdd &&
                                    <button type="button" className="btn-white-s" onClick={(e) => onAddChildMeta(e, item)}>추가</button>}
                                </td>
                                <td className="leading-4">{item.creator}</td>
                                <td className="w-24">
                                    <button type="button" className="btn-white-s" onClick={(e) => onEditMeta(e, item)}>보기</button>
                                </td>
                            </tr>
                        )
                        })}
                    </tbody>   
                </table>                 

                <div className="relative mt-5 mb-5">
                    <div className="flex">
                        <button type="button" className="btn-white mr-2" onClick={() => {
                            setViewHList(false);
                        }}>목록</button>
                        <button type="button" className="btn-white" onClick={onAddMetaToCurrent}>추가<br/>(1Depth)</button>

                        <div className="absolute top-0 right-0 text-right">
                            <button type="button" className="btn-sky" onClick={onAddTopMeta}>등록</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>}
                
            
        {isEditing && 
            <MetaAddEdit brand={props.brand} menuName={metaName} item={currItem} parentItem={parentItem} onCloseEdit={onCloseEdit} showModal={props.showModal} showLoading={props.showLoading} />
        }

        {isBulkEditing &&
            <MetaBulkRegister OnClose={() => {
                setIsBulkEditing(false);
            }} brand={props.brand} menuName={metaName} showModal={props.showModal} showLoading={props.showLoading} />}

        {viewStudyMap && 
            <MetaStudyMap brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewStudyMap(false);
            }} />
        }

        {viewStudyMapEng && 
            <MetaStudyMapEng brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewStudyMapEng(false);
            }} />
        }

        {viewContentsItem && 
            <MetaContentsItem brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewContentsItem(false);
            }} />
        }

        {viewAchievementStandard && 
            <MetaAchievementStandard brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewAchievementStandard(false);
            }} />
        }

        {viewMaterial && 
            <MetaMaterial brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewMaterial(false);
            }} />
        }

        {viewCommunication && 
            <MetaCommunication brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewCommunication(false);
            }} />
        }

        {viewLanguageFormat && 
            <MetaLanguageFormat brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewLanguageFormat(false);
            }} />
        }

    </div>
    );
}

export default MetaManager;
