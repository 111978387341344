import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IArticle, IBrand, IMenu, IMeta, S3Server } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";

interface IPopupContentsList {
    brand: IBrand;
    textType: string;
    multiSelect?: boolean;
    mainArticle?: IArticle; 
    onClose: () => void;   
    onSelect: (item: any) => void; 
    onSelectMulti?: (items: any[]) => void; 
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function PopupContentsList(props: IPopupContentsList) {
    
    const [list, setList] = useState([] as any);
    // const [viewType, setViewType] = useState("");  //s:small, b:big
    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [studyMapsList, setStudyMapsList] = useState<IMeta[]>([]);
    const [studyMap0List, setStudyMap0List] = useState<IMeta[]>([]);    
    const [studyMap1List, setStudyMap1List] = useState<IMeta[]>([]);
    const [studyMap2List, setStudyMap2List] = useState<IMeta[]>([]);
    const [studyMap3List, setStudyMap3List] = useState<IMeta[]>([]);
    const [studyMap4List, setStudyMap4List] = useState<IMeta[]>([]);
    const [studyMap5List, setStudyMap5List] = useState<IMeta[]>([]);

    const [curriDepth, setCurriDepth] = useState(0);
    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);
    // const [currArticleCategory, setCurrArticleCategory] = useState("");

    const [modalShow, setModalShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);

    const [title, setTitle] = useState("");

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            // setViewList(true);
            // setViewType("s");
            // setCurrArticleCategory("교과");

            const selectTypeCB = document.getElementById("rd_select_cb") as HTMLInputElement;
            if(selectTypeCB) {
                selectTypeCB.checked = true;
            }

            let _title = "문항 검색";
            if(props.textType === "Analogous_Main") {
                _title = "대표문항 불러오기";
            } 
            if(props.textType === "Analogous_Analogous") {
                _title = "유사문항 불러오기";

                if(props.mainArticle) {

                    getStudyMapList();
                    // getStudyMapList(props.mainArticle?.curriBook);
                    logger.log("mainArticle", props.mainArticle);
    
                    const PCL_curriBook = document.getElementById("PCL_curriBook") as HTMLSelectElement;
                    if(PCL_curriBook) {
                        const curriBook = props.mainArticle.metaMap.find((meta) => meta.name === "curriBook");
                        if(curriBook) {
                            PCL_curriBook.value = String(curriBook.code);
                            PCL_curriBook.disabled = true;
                            _onChangeCurriBook(String(curriBook.code));
                        }
                    }
    
                    // const PCL_studyMap1 = document.getElementById("PCL_studyMap1") as HTMLSelectElement;
                    // const PCL_studyMap2 = document.getElementById("PCL_studyMap2") as HTMLSelectElement;
                    // const PCL_studyMap3 = document.getElementById("PCL_studyMap3") as HTMLSelectElement;
                    // const PCL_studyMap4 = document.getElementById("PCL_studyMap4") as HTMLSelectElement;
                    // const PCL_studyMap5 = document.getElementById("PCL_studyMap5") as HTMLSelectElement;
                
                    // if(PCL_studyMap1) {
                    //     const studyMap1 = props.mainArticle.metaMap.find((meta) => meta.name === "studyMap1");
                    //     if(studyMap1) {
                    //         logger.log("studyMap1", studyMap1);
        
                    //         PCL_studyMap1.value = String(studyMap1.id);
                    //         // PCL_studyMap1.disabled = true;          
                            
                    //         const _filtered_2 = (studyMapsList as IMeta[]).filter((item) => item.depth === 3);
                    //         logger.log("onChangeStudyMap1", _filtered_2);
                    
                    //         const result = _filtered_2.filter((item) => item.parent_id === Number(studyMap1.id))
                    //         setStudyMap2List(result);
                    
                    //         PCL_studyMap2.selectedIndex = 0;
                    
                    //         setStudyMap3List([]);
                    //         setStudyMap4List([]);
                    //         setStudyMap5List([]);                      
                    //     }  
                    // }

                    // if(PCL_studyMap2) {
                    //     const studyMap2 = props.mainArticle.metaMap.find((meta) => meta.name === "studyMap2");
                    //     if(studyMap2) {
                    //         logger.log("studyMap2", studyMap2);
        
                    //         PCL_studyMap2.value = String(studyMap2.id);
                    //         // PCL_studyMap2.disabled = true;    
                            
                    //         const _filtered_3 = (studyMapsList as IMeta[]).filter((item) => item.depth === 4);
                    //         logger.log("onChangeStudyMap2", _filtered_3);
                    
                    //         const result = _filtered_3.filter((item) => item.parent_id === Number(studyMap2.id))
                    //         setStudyMap3List(result);
                    
                    //         PCL_studyMap3.selectedIndex = 0;
                    
                    //         setStudyMap4List([]);
                    //         setStudyMap5List([]);                       
                    //     }                          
                    // }
                              
                    // const studyMap3 = props.mainArticle.metaMap.find((meta) => meta.name === "studyMap3");
                    // if(studyMap3) {
                    //     logger.log("studyMap3", studyMap3);
    
                    //     PCL_studyMap3.value = String(studyMap3.id);
                    //     // PCL_studyMap2.disabled = true;    
                        
                    //     const _filtered_4 = (studyMapsList as IMeta[]).filter((item) => item.depth === 5);
                    //     logger.log("onChangeStudyMap3", _filtered_4);
                
                    //     const result = _filtered_4.filter((item) => item.parent_id === Number(studyMap3.id))
                    //     setStudyMap4List(result);
                
                    //     PCL_studyMap4.selectedIndex = 0;
                
                    //     setStudyMap5List([]);                       
                    // }                        
                    // const studyMap4 = props.mainArticle.metaMap.find((meta) => meta.name === "studyMap4");
                    // if(studyMap4) {
                    //     logger.log("studyMap4", studyMap4);
    
                    //     PCL_studyMap4.value = String(studyMap4.id);
                    //     // PCL_studyMap2.disabled = true;    
                        
                    //     const _filtered_5 = (studyMapsList as IMeta[]).filter((item) => item.depth === 6);
                    //     logger.log("onChangeStudyMap4", _filtered_5);
                
                    //     const result = _filtered_5.filter((item) => item.parent_id === Number(studyMap4.id))
                    //     setStudyMap5List(result);
                
                    //     PCL_studyMap5.selectedIndex = 0;
                    // }                 
    
                    const PCL_contentsType = document.getElementById("PCL_contentsType") as HTMLInputElement;
                    if(PCL_contentsType) {
                        PCL_contentsType.value = "문항";
                        PCL_contentsType.disabled = true;
                    }
                    const PCL_public = document.getElementById("PCL_public") as HTMLInputElement;
                    if(PCL_public) {
                        PCL_public.value = "1";
                    }                                    
                    const PCL_difficulty = document.getElementById("PCL_difficulty") as HTMLInputElement;
                    if(PCL_difficulty) {       
                        if(props.mainArticle) {
                            const difficulty = props.mainArticle.metaMap.find((meta) => meta.name === "difficulty");
                            if(difficulty) {
                                PCL_difficulty.value = String(difficulty.id);
                            }
                        }             
                    }    
                }                
            } 
            if(props.textType === "Analogous_Twin") {
                _title = "쌍둥이문항 불러오기";
            } 
            setTitle(_title);

            const _pageNum = "10";
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }

            // const viewlist1 = document.getElementById("rd-viewlist1") as HTMLInputElement;
            // viewlist1.checked = true;

            const div = document.getElementsByClassName("SG-datepicker__input-container");
            if(div) {
                
                const new_PCL_sdate = document.getElementById("new_PCL_sdate") as HTMLInputElement;
                if(!new_PCL_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_PCL_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.style.paddingTop = "0.2rem";
                    node.style.paddingBottom = "0.2rem";
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[div.length-2].appendChild(node)
    
                }

                const PCL_sdate = document.getElementById("PCL_sdate") as HTMLInputElement;
                if(PCL_sdate) {
                    PCL_sdate.style.display = "none";
                }

                const new_PCL_edate = document.getElementById("new_PCL_edate") as HTMLInputElement;
                if(!new_PCL_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_PCL_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.style.paddingTop = "0.2rem";
                    node_e.style.paddingBottom = "0.2rem";

                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[div.length-1].appendChild(node_e)
                }

                const PCL_edate = document.getElementById("PCL_edate") as HTMLInputElement;
                if(PCL_edate) {
                    PCL_edate.style.display = "none";
                }
            }
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            // refreshList();
            onSearch();
        }
        preBrand = props.brand;
    }, [props.brand])
    
    const refreshList = () => {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        setCurrPage(1);

        let articleCategoryId = undefined;
        if(curriInfo && curriInfo.articleCategory) {
            const articleCategory = curriInfo.articleCategory as IMeta[];
            const _articleCategory = articleCategory.find((article, _) => article.val === "교과");
            if(_articleCategory) {
                articleCategoryId = _articleCategory.id;
            }
        }

        const data = {
            brand_id: brand.id ? brand.id : null,
            articleCategory: articleCategoryId,
            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10,
        };
        setSearchData(data);
        _onSearch(data);

        logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        if(data) {
            data.detail = true;
            if(props.textType.includes("Analogous_")) {
                data.is_publicOpen = true;
            }
        }

        setViewList(false);

        logger.log("_onSearch", data);

        const list = await util.getArticleSearch(data);
        setList(list);
        logger.log("onSearch", data, list);
        setViewList(true);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }

        const PCL_sdate = document.getElementById("new_PCL_sdate") as HTMLInputElement;
        const PCL_edate = document.getElementById("new_PCL_edate") as HTMLInputElement;        
    
        let sdate = "";
        let edate = "";
        const arrSD = PCL_sdate.value.split("-");
        const arrED = PCL_edate.value.split("-");
        if(arrSD.length === 3 && arrED.length === 3) {
            sdate = PCL_sdate.value;
            edate = PCL_edate.value 
        }  

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const PCL_curriBook = document.getElementById("PCL_curriBook") as HTMLSelectElement;
        const PCL_curriBook_val = curriInfo.curriBook[PCL_curriBook.selectedIndex-1];

        const PCL_html_curriUnit1 = document.getElementById("PCL_curriUnit1") as HTMLSelectElement;
        const PCL_html_curriUnit2 = document.getElementById("PCL_curriUnit2") as HTMLSelectElement;
        const PCL_html_curriUnit3 = document.getElementById("PCL_curriUnit3") as HTMLSelectElement;
        const PCL_html_curriUnit4 = document.getElementById("PCL_curriUnit4") as HTMLSelectElement;
        const PCL_html_curriUnit5 = document.getElementById("PCL_curriUnit5") as HTMLSelectElement;
    
        const PCL_html_curriUnit1_val = curriUnit1 ? (curriUnit1[PCL_html_curriUnit1.selectedIndex-1] as IMeta) : undefined;
        const PCL_html_curriUnit2_val = curriUnit2 ? (curriUnit2[PCL_html_curriUnit2.selectedIndex-1] as IMeta) : undefined;
        const PCL_html_curriUnit3_val = curriUnit3 ? (curriUnit3[PCL_html_curriUnit3.selectedIndex-1] as IMeta) : undefined;
        const PCL_html_curriUnit4_val = curriUnit4 ? (curriUnit4[PCL_html_curriUnit4.selectedIndex-1] as IMeta) : undefined;
        const PCL_html_curriUnit5_val = curriUnit5 ? (curriUnit5[PCL_html_curriUnit5.selectedIndex-1] as IMeta) : undefined;

        const PCL_contentsType = document.getElementById("PCL_contentsType") as HTMLSelectElement;
        const PCL_public = document.getElementById("PCL_public") as HTMLSelectElement;
        const PCL_review = document.getElementById("PCL_review") as HTMLSelectElement;

        let articleTypeId = undefined;
        if(PCL_contentsType.value !== "") {
            if(curriInfo && curriInfo.articleType) {
                const articleType = curriInfo.articleType as IMeta[];
                const _articleType = articleType.find((article, _) => article.val === PCL_contentsType.value);
                if(_articleType) {
                    articleTypeId = _articleType.id;
                }
            }    
        }

        // let articleCategoryId = undefined;
        // if(curriInfo && curriInfo.articleCategory) {
        //     const articleCategory = curriInfo.articleCategory as IMeta[];
        //     const _articleCategory = articleCategory.find((article, _) => article.val === currArticleCategory);
        //     if(_articleCategory) {
        //         articleCategoryId = _articleCategory.id;
        //     }
        // }

        const PCL_keyword = document.getElementById("PCL_keyword") as HTMLInputElement;        
        const PCL_keyword_type = document.getElementById("PCL_keyword_type") as HTMLSelectElement;

        logger.log("PCL_keyword", PCL_keyword.value);
        logger.log("PCL_keyword_type", PCL_keyword_type.value);

        let search = "";
        if(PCL_keyword) {
            search = PCL_keyword.value;
        }
        let searchField = "";
        if(PCL_keyword_type) {
            searchField = PCL_keyword_type.value;
        }

        const PCL_studyMap = document.getElementById("PCL_studyMap") as HTMLSelectElement;
        const PCL_studyMap_val = studyMap0List[PCL_studyMap.selectedIndex-1];

        const PCL_studyMap1 = document.getElementById("PCL_studyMap1") as HTMLSelectElement;
        const PCL_studyMap2 = document.getElementById("PCL_studyMap2") as HTMLSelectElement;
        const PCL_studyMap3 = document.getElementById("PCL_studyMap3") as HTMLSelectElement;
        const PCL_studyMap4 = document.getElementById("PCL_studyMap4") as HTMLSelectElement;
        const PCL_studyMap5 = document.getElementById("PCL_studyMap5") as HTMLSelectElement;
    
        const PCL_studyMap1_val = studyMap1List.length > 0 ? (studyMap1List[PCL_studyMap1.selectedIndex-1] as IMeta) : undefined;
        const PCL_studyMap2_val = studyMap2List.length > 0 ? (studyMap2List[PCL_studyMap2.selectedIndex-1] as IMeta) : undefined;
        const PCL_studyMap3_val = studyMap3List.length > 0 ? (studyMap3List[PCL_studyMap3.selectedIndex-1] as IMeta) : undefined;
        const PCL_studyMap4_val = studyMap4List.length > 0 ? (studyMap4List[PCL_studyMap4.selectedIndex-1] as IMeta) : undefined;
        const PCL_studyMap5_val = studyMap5List.length > 0 ? (studyMap5List[PCL_studyMap5.selectedIndex-1] as IMeta) : undefined;

        const metaMap: IMeta[] = [];

        const selectTypeSM = document.getElementById("rd_select_sm") as HTMLInputElement;
        const selectTypeCB = document.getElementById("rd_select_cb") as HTMLInputElement;

        logger.log("selectTypeSM", selectTypeSM.checked, selectTypeCB.checked);

        if(selectTypeSM.checked) {

            logger.log("PCL_studyMap", PCL_studyMap1_val, PCL_studyMap2_val, PCL_studyMap3_val, PCL_studyMap4_val, PCL_studyMap5_val);

            // if(PCL_studyMap_val) {
            //     metaMap.push(PCL_studyMap_val);
            // }
            // if(PCL_studyMap1_val) {
            //     metaMap.push(PCL_studyMap1_val);
            // }
            // if(PCL_studyMap2_val) {
            //     metaMap.push(PCL_studyMap2_val);
            // }
            // if(PCL_studyMap3_val) {
            //     metaMap.push(PCL_studyMap3_val);
            // }
            // if(PCL_studyMap4_val) {
            //     metaMap.push(PCL_studyMap4_val);
            // }
            // if(PCL_studyMap5_val) {
            //     metaMap.push(PCL_studyMap5_val);
            // }    

            if(PCL_studyMap_val) {
                if(PCL_studyMap1_val) {
                    if(PCL_studyMap2_val) {
                        if(PCL_studyMap3_val) {
                            if(PCL_studyMap4_val) {
                                if(PCL_studyMap5_val) {
                                    metaMap.push(PCL_studyMap5_val);
                                } else {
                                    metaMap.push(PCL_studyMap4_val);
                                }
                            } else {
                                metaMap.push(PCL_studyMap3_val);
                            }
                        } else {
                            metaMap.push(PCL_studyMap2_val);
                        }
                    } else {
                        metaMap.push(PCL_studyMap1_val);
                    }
                } else {
                    metaMap.push(PCL_studyMap_val);
                }
            }

        } else if(selectTypeCB.checked) {

            // if(PCL_curriBook_val) {
            //     metaMap.push(PCL_curriBook_val);
            // }
            // if(PCL_html_curriUnit1_val) {
            //     metaMap.push(PCL_html_curriUnit1_val);
            // }
            // if(PCL_html_curriUnit2_val) {
            //     metaMap.push(PCL_html_curriUnit2_val);
            // }
            // if(PCL_html_curriUnit3_val) {
            //     metaMap.push(PCL_html_curriUnit3_val);
            // }
            // if(PCL_html_curriUnit4_val) {
            //     metaMap.push(PCL_html_curriUnit4_val);
            // }
            // if(PCL_html_curriUnit5_val) {
            //     metaMap.push(PCL_html_curriUnit5_val);
            // }

            if(PCL_curriBook_val) {
                if(PCL_html_curriUnit1_val) {
                    if(PCL_html_curriUnit2_val) {
                        if(PCL_html_curriUnit3_val) {
                            if(PCL_html_curriUnit4_val) {
                                if(PCL_html_curriUnit5_val) {
                                    metaMap.push(PCL_html_curriUnit5_val);
                                } else {
                                    metaMap.push(PCL_html_curriUnit4_val);
                                }
                            } else {
                                metaMap.push(PCL_html_curriUnit3_val);
                            }
                        } else {
                            metaMap.push(PCL_html_curriUnit2_val);
                        }
                    } else {
                        metaMap.push(PCL_html_curriUnit1_val);
                    }
                } else {
                    metaMap.push(PCL_curriBook_val);
                }
            }            
        }

        const PCL_difficulty = document.getElementById("PCL_difficulty") as HTMLSelectElement;
        if(PCL_difficulty.selectedIndex > 0) {
            const PCL_difficulty_val = curriInfo.difficulty[PCL_difficulty.selectedIndex-1] as IMeta;
            metaMap.push(PCL_difficulty_val);    
        }

        const data = {
            brand_id: brand.id ? brand.id : null,

            // articleCategory: articleCategoryId,
            articleType: articleTypeId,

            // name: PCL_keyword && PCL_keyword.value !== "" ? PCL_name.value : undefined,
            // keyword: PCL_keyword && PCL_keyword.value !== "" ? PCL_keyword.value : undefined,
            reg_sdate: sdate,  
            reg_edate: edate,

            is_publicOpen: PCL_public.value === "" ? undefined : PCL_public.value === "1" ? true : false,
            review: PCL_review.value === "" ? undefined : Number(PCL_review.value),

            search: search,
            searchField: searchField,

            // curriBook: PCL_curriBook_val ? PCL_curriBook_val.id : undefined,
            // curriUnit1: PCL_html_curriUnit1_val ? PCL_html_curriUnit1_val.id : undefined,
            // curriUnit2: PCL_html_curriUnit2_val ? PCL_html_curriUnit2_val.id : undefined,
            // curriUnit3: PCL_html_curriUnit3_val ? PCL_html_curriUnit3_val.id : undefined,
            // curriUnit4: PCL_html_curriUnit4_val ? PCL_html_curriUnit4_val.id : undefined,
            // curriUnit5: PCL_html_curriUnit5_val ? PCL_html_curriUnit5_val.id : undefined,

            metaMap: metaMap,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10,

            excludingQuestionType_chqz: props.textType === "Analogous_Analogous" ? true : undefined, //연쇄형 문항 제외조건
            excludingArticleArticle: props.textType === "Analogous_Analogous" ? true : undefined, //타 대표문항 하위에 기 등록된 유사문항 제외조건
        };

        logger.log("onSearch", data);

        setSearchData(data);

        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("Contents_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        _onSearch(_searchData);            
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    async function getDescendantsList(code: string) {

        props.showLoading(true);

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {
            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);
        }

        props.showLoading(false);
    }

    function _onChangeCurriBook(code: string) {

        getDescendantsList(code);

        const PCL_html_curriUnit1 = document.getElementById("PCL_curriUnit1") as HTMLSelectElement;
        const PCL_html_curriUnit2 = document.getElementById("PCL_curriUnit2") as HTMLSelectElement;
        const PCL_html_curriUnit3 = document.getElementById("PCL_curriUnit3") as HTMLSelectElement;
        const PCL_html_curriUnit4 = document.getElementById("PCL_curriUnit4") as HTMLSelectElement;
        const PCL_html_curriUnit5 = document.getElementById("PCL_curriUnit5") as HTMLSelectElement;
    
        if(PCL_html_curriUnit1) {
            PCL_html_curriUnit1.selectedIndex = 0;
        }
        if(PCL_html_curriUnit2) {
            PCL_html_curriUnit2.selectedIndex = 0;
        }
        if(PCL_html_curriUnit3) {
            PCL_html_curriUnit3.selectedIndex = 0;
        }
        if(PCL_html_curriUnit4) {
            PCL_html_curriUnit4.selectedIndex = 0;
        }
        if(PCL_html_curriUnit5) {
            PCL_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit2([]);
        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        _onChangeCurriBook(e.target.value);
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit1", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);

        const PCL_html_curriUnit2 = document.getElementById("PCL_curriUnit2") as HTMLSelectElement;
        const PCL_html_curriUnit3 = document.getElementById("PCL_curriUnit3") as HTMLSelectElement;
        const PCL_html_curriUnit4 = document.getElementById("PCL_curriUnit4") as HTMLSelectElement;
        const PCL_html_curriUnit5 = document.getElementById("PCL_curriUnit5") as HTMLSelectElement;
    
        PCL_html_curriUnit2.selectedIndex = 0;
        PCL_html_curriUnit3.selectedIndex = 0;
        PCL_html_curriUnit4.selectedIndex = 0;
        PCL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        const PCL_html_curriUnit3 = document.getElementById("PCL_curriUnit3") as HTMLSelectElement;
        const PCL_html_curriUnit4 = document.getElementById("PCL_curriUnit4") as HTMLSelectElement;
        const PCL_html_curriUnit5 = document.getElementById("PCL_curriUnit5") as HTMLSelectElement;
    
        PCL_html_curriUnit3.selectedIndex = 0;
        PCL_html_curriUnit4.selectedIndex = 0;
        PCL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        const PCL_html_curriUnit4 = document.getElementById("PCL_curriUnit4") as HTMLSelectElement;
        const PCL_html_curriUnit5 = document.getElementById("PCL_curriUnit5") as HTMLSelectElement;
    
        PCL_html_curriUnit4.selectedIndex = 0;
        PCL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        const PCL_html_curriUnit5 = document.getElementById("PCL_curriUnit5") as HTMLSelectElement;
    
        PCL_html_curriUnit5.selectedIndex = 0;
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }
    
    async function getStudyMapList(cb_id: number | null = null) {

        // props.showLoading(true);

        const metaMap: {"name": string, "id": number}[] = [];

        const data = {
            "brand_id": util.getCurrentBrand()?.id,
            "name": "studyMap",
            "depth": "1",
            "metaMap": metaMap,
            "is_active": true,
        }

        if(cb_id !== null) {
            metaMap.push({"name":"curriBook", "id":Number(cb_id)});
            data.metaMap = metaMap;
        }
        
        const list = await util.searchMeta(data);

        // const list = await util.getMetaList("1", "studyMap");
        // const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	

        if(list) {
            
            setStudyMap0List(list);

            const PCL_studyMap = document.getElementById("PCL_studyMap") as HTMLSelectElement;
            if(PCL_studyMap) {
                const mainArticle = props.mainArticle;
                if(mainArticle) {
                    const studyMap = mainArticle.metaMap.find((meta) => meta.name === "studyMap");
                    if(studyMap) {
                        logger.log("studyMap", studyMap, PCL_studyMap);
    
                        const _studyMapsList = await getStudyMapDescendantsList(studyMap.code);

                        const curriSchool = mainArticle.metaMap.find((meta) => meta.name === "curriSchool");

                        let school = "";
                        if(curriSchool) {
                            school = curriSchool.val;
                        }

                        setTimeout(() => {

                            PCL_studyMap.value = String(studyMap.id);

                            const studyMap1 = mainArticle.metaMap.find((meta) => meta.name === "studyMap1");
                            if(studyMap1) {
                                setTimeout(() => {
                                    const PCL_studyMap1 = document.getElementById("PCL_studyMap1") as HTMLSelectElement;
                                    PCL_studyMap1.value = String(studyMap1.id);

                                    const studyMap2s = _studyMapsList?.filter((item) => item.parent_id === studyMap1.id);
                                    const _studyMap2s = studyMap2s ? studyMap2s : [];
                                    setStudyMap2List(_studyMap2s);

                                    let studyMap2 : IMeta | undefined = undefined;
                                    for (let i = 0; i < _studyMap2s.length; i++) {
                                        const sm = _studyMap2s[i];                                        
                                        for (let j = 0; j < mainArticle.metaMap.length; j++) {
                                            const mm = mainArticle.metaMap[j];
                                            if(sm.id === mm.id) {
                                                studyMap2 = mm;
                                            }
                                        }                                           
                                    }                                    
                                    // const studyMap2 = mainArticle.metaMap.find((meta) => meta.name === "studyMap2");
                                    if(studyMap2) {
                                        setTimeout(() => {
                                            const PCL_studyMap2 = document.getElementById("PCL_studyMap2") as HTMLSelectElement;
                                            PCL_studyMap2.value = String(studyMap2 ? studyMap2.id : "");
        
                                            const studyMap3s = _studyMapsList?.filter((item) => item.parent_id === studyMap2?.id);
                                            const _studyMap3s = studyMap3s ? studyMap3s : [];
                                            setStudyMap3List(_studyMap3s);                                            
        
                                            if(school === "초등학교") {
                                                return;
                                            }

                                            let studyMap3 : IMeta | undefined = undefined;
                                            for (let i = 0; i < _studyMap3s.length; i++) {
                                                const sm = _studyMap3s[i];                                        
                                                for (let j = 0; j < mainArticle.metaMap.length; j++) {
                                                    const mm = mainArticle.metaMap[j];
                                                    if(sm.id === mm.id) {
                                                        studyMap3 = mm;
                                                    }
                                                }                                        
                                            }                                    

                                            if(studyMap3) {
                                                setTimeout(() => {
                                                    const PCL_studyMap3 = document.getElementById("PCL_studyMap3") as HTMLSelectElement;
                                                    PCL_studyMap3.value = String(studyMap3 ? studyMap3.id : "");
                
                                                    const studyMap4s = _studyMapsList?.filter((item) => item.parent_id === studyMap3?.id);
                                                    const _studyMap4s = studyMap4s ? studyMap4s : [];
                                                    setStudyMap4List(_studyMap4s);
                        
                                                    let studyMap4 : IMeta | undefined = undefined;
                                                    for (let i = 0; i < _studyMap4s.length; i++) {
                                                        const sm = _studyMap4s[i];                                        
                                                        for (let j = 0; j < mainArticle.metaMap.length; j++) {
                                                            const mm = mainArticle.metaMap[j];
                                                            if(sm.id === mm.id) {
                                                                studyMap4 = mm;
                                                            }
                                                        }                                        
                                                    }                                    
        
                                                    if(studyMap4) {
                                                        setTimeout(() => {
                                                            const PCL_studyMap4 = document.getElementById("PCL_studyMap4") as HTMLSelectElement;
                                                            PCL_studyMap4.value = String(studyMap4 ? studyMap4.id : "");
                        
                                                            const studyMap5s = _studyMapsList?.filter((item) => item.parent_id === studyMap4?.id);
                                                            const _studyMap5s = studyMap5s ? studyMap5s : [];
                                                            setStudyMap5List(_studyMap5s);
                                
                                                        }, 100);    
                                                    }
        
                                                }, 100);    
                                            }
        
                                        }, 100);    
                                    }
        
                                }, 100);    
                            }

                        }, 100);
                        // PCL_studyMap.disabled = true;
                    }                           
                }
            }

        }
        // props.showLoading(false);

        logger.log("getStudyMapList", data, list);
    }

    async function getStudyMapDescendantsList(code: string) {

        props.showLoading(true);

        const list = await util.getDescendantsList(code);

        const _studyMap = list.find((item) => item.code === code);
        if(!_studyMap) {
            return;
        }

        const _list = list.filter((item) => item.is_active === true);

        setStudyMapsList(_list);

        const _filtered_1 = _list.filter((item) => item.name === "studyMap1");
        setStudyMap1List(_filtered_1);

        logger.log("getStudyMapDescendantsList", list, _list);

        props.showLoading(false);

        return _list;
    }

    async function onChangeStudyMap(e: ChangeEvent<HTMLSelectElement>) {
        if(e.target.selectedIndex > 0) {
            const _studyMap = studyMap0List[e.target.selectedIndex-1];
            logger.log("onChangeStudyMap", _studyMap);
    
            logger.log("onChangeStudyMap");
            getStudyMapDescendantsList(_studyMap.code);    
        } else {
            setStudyMap1List([]);
        }

        const PCL_studyMap1 = document.getElementById("PCL_studyMap1") as HTMLSelectElement;
    
        PCL_studyMap1.selectedIndex = 0;

        setStudyMap2List([]);
        setStudyMap3List([]);
        setStudyMap4List([]);
        setStudyMap5List([]);
    }

    async function onChangeStudyMap1(e: ChangeEvent<HTMLSelectElement>) {
        // const _filtered_2 = (studyMapsList as IMeta[]).filter((item) => item.depth === 3);
        // logger.log("onChangeStudyMap1", _filtered_2);

        const result = studyMapsList.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap2List(result);
        logger.log("onChangeStudyMap1", e.target.value, result);

        const PCL_studyMap2 = document.getElementById("PCL_studyMap2") as HTMLSelectElement;
    
        PCL_studyMap2.selectedIndex = 0;

        setStudyMap3List([]);
        setStudyMap4List([]);
        setStudyMap5List([]);
    }

    async function onChangeStudyMap2(e: ChangeEvent<HTMLSelectElement>) {
        // const _filtered_3 = (studyMapsList as IMeta[]).filter((item) => item.depth === 4);
        // logger.log("onChangeStudyMap2", _filtered_3);

        const result = studyMapsList.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap3List(result);
        logger.log("onChangeStudyMap2", e.target.value, result);

        const PCL_studyMap3 = document.getElementById("PCL_studyMap3") as HTMLSelectElement;
    
        PCL_studyMap3.selectedIndex = 0;

        setStudyMap4List([]);
        setStudyMap5List([]);
    }
    async function onChangeStudyMap3(e: ChangeEvent<HTMLSelectElement>) {
        // const _filtered_4 = (studyMapsList as IMeta[]).filter((item) => item.depth === 5);
        // logger.log("onChangeStudyMap3", _filtered_4);

        const result = studyMapsList.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap4List(result);
        logger.log("onChangeStudyMap3", e.target.value, result);

        const PCL_studyMap4 = document.getElementById("PCL_studyMap4") as HTMLSelectElement;
    
        PCL_studyMap4.selectedIndex = 0;

        setStudyMap5List([]);
    }
    async function onChangeStudyMap4(e: ChangeEvent<HTMLSelectElement>) {
        // const _filtered_5 = (studyMapsList as IMeta[]).filter((item) => item.depth === 6);
        // logger.log("onChangeStudyMap4", _filtered_5);

        const result = studyMapsList.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap5List(result);
        logger.log("onChangeStudyMap4", e.target.value, result);

        const PCL_studyMap5 = document.getElementById("PCL_studyMap5") as HTMLSelectElement;

        PCL_studyMap5.selectedIndex = 0;
    }

    function onClickSDateIcon() : void {
        const new_PCL_sdate = document.getElementById("new_PCL_sdate") as HTMLInputElement;
        if(new_PCL_sdate) {
            // new_PCL_sdate.value = "";
            const PCL_sdate = document.getElementById("PCL_sdate") as HTMLInputElement;
            PCL_sdate.value = "";
            PCL_sdate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickSDate", div, div.length);
                    const PCL_edate = document.getElementById("PCL_edate") as HTMLInputElement;
                    PCL_edate.click();
                }
            }, 10);    
        }
    }
        
    function onClickEDateIcon() : void {
        const new_PCL_edate = document.getElementById("new_PCL_edate") as HTMLInputElement;
        if(new_PCL_edate) {
            // new_PCL_edate.value = "";
            const PCL_edate = document.getElementById("PCL_edate") as HTMLInputElement;
            PCL_edate.value = "";
            PCL_edate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickEDate", div, div.length);
                    const PCL_sdate = document.getElementById("PCL_sdate") as HTMLInputElement;
                    PCL_sdate.click();
                }
            }, 10);    
        }
    }

    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        logger.log("onChangeCalendar", e.target.id)

        if(e.target.id === "PCL_sdate") {
            const new_PCL_sdate = document.getElementById("new_PCL_sdate") as HTMLInputElement;
            if(new_PCL_sdate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_PCL_sdate.value = val;
            }
        } else if(e.target.id === "PCL_edate") {
            const new_PCL_edate = document.getElementById("new_PCL_edate") as HTMLInputElement;
            if(new_PCL_edate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_PCL_edate.value = val;
            }
        }
    }

    // function onChangeViewType() {
    //     const viewlist1 = document.getElementById("rd-viewlist1") as HTMLInputElement;
    //     if(viewlist1.checked) {
    //         setViewType("s");    
    //     } else {
    //         setViewType("b");
    //     }        
    // }

    // function onChangeCurriContents(newArticleCategory: string) {

    //     setCurrArticleCategory(newArticleCategory);

    //     let articleCategoryId = undefined;
    //     if(curriInfo && curriInfo.articleCategory) {
    //         const articleCategory = curriInfo.articleCategory as IMeta[];
    //         const _articleCategory = articleCategory.find((article, _) => article.val === newArticleCategory);
    //         if(_articleCategory) {
    //             articleCategoryId = _articleCategory.id;
    //         }
    //     }

    //     const _searchData = searchData;
    //     _searchData.articleCategory = articleCategoryId;

    //     logger.log("onChangeCurriContents", newArticleCategory, articleCategoryId, _searchData);

    //     setSearchData(_searchData);
    //     _onSearch(_searchData);
    // }

    async function onChangePublicOpen(item: IArticle) {
        const PCL_is_publicOpen = document.getElementById("PCL_is_publicOpen") as HTMLInputElement;
        if(PCL_is_publicOpen) {
            logger.log("PCL_is_publicOpen.checked", item, PCL_is_publicOpen.checked);

            const data = {
                saveType: "update",
                article: {
                    id: item.id,
                    is_publicOpen: !item.is_publicOpen,
                    loginUserId : util.getUserInfo("uid"),
                },
            };

            logger.log(data);

            await util.updateArticle(data);
            _onSearch(searchData);
        }
    }

    function onReview(item: IArticle) {
        if(item && item.review === 0) {
            setModalShow(true);
            setSelectedItem(item);
        }
    }

    async function onReviewOk() {
        if(selectedItem && selectedItem.review === 0) {    
            const data = {
                saveType: "update",
                article: {
                    id: selectedItem.id,
                    review: 1,
                    loginUserId : util.getUserInfo("uid"),
                },
            };

            logger.log(data);

            await util.updateArticle(data);
            _onSearch(searchData);
        }

        onReviewClose();
    }

    function onReviewClose() {
        setModalShow(false);
        setSelectedItem(null);
    }

    async function onPreView(item: IArticle) {
        // alert("미리보기 작업중...");
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }
 
        const sets = await util.getSetsByArticleId(item.id);
        logger.log("onPreView", sets);
        if(sets && sets.length > 0) {
            const url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&set_id="+sets[0].id+"&token="+util.getUserInfo("token");
            window.open(url, "_blank", "noopener, noreferrer");    
        }
    }

    async function onEditPage(item: IArticle | null) {
        // alert("편집 작업중...");
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }
 
        let url = "";
        if(item) {
            const sets = await util.getSetsByArticleId(item.id);
            if(sets && sets.length > 0) {
                url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                +"&set_id="+sets[0].id+"&token="+util.getUserInfo("token");
            }
        } else {
            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&create=y"+"&token="+util.getUserInfo("token");
        }
        window.open(url, "_blank", "noopener, noreferrer");
    }

    function onSelect(item: IArticle) {
        props.onSelect(item);
    }

    function onSelectMulti() {

        const articles : IArticle[] = [];
        const list_ck = document.getElementsByName("list_ck");

        let checkedCnt = 0;
        for (let i = 0; i < list_ck.length; i++) {
            const element = list_ck[i] as HTMLInputElement;
            if(element.checked) {
                if(list[i]) {
                    checkedCnt++;
                    articles.push(list[i]);
                }
            }
        }

        logger.log("onSelectMulti", articles);

        if(props.onSelectMulti) {
            props.onSelectMulti(articles);
        }
    }

    // function onSelectMulti() {
    //     const checkedArticles = document.getElementById("checkedArticles") as HTMLInputElement;
    //     if(checkedArticles) {
    //         const checkedArticles_val = checkedArticles.value;
    //         if(props.onSelectMulti) {
    //             props.onSelectMulti(checkedArticles_val);
    //         }
    //     }
    // }

    // function onChangeChk(e: ChangeEvent<HTMLInputElement>, item: IArticle) {

    //     const checkedArticles = document.getElementById("checkedArticles") as HTMLInputElement;
    //     if(checkedArticles) {
    //         const checkedArticles_val = checkedArticles.value;
    //         const arr = checkedArticles_val.split(",");
    //         const isIn = arr.find((_item) => _item === String(item.id));

    //         if(e.target.checked) {            
    //             if(!isIn) {
    //                 if(checkedArticles.value === "") {
    //                     checkedArticles.value = String(item.id);
    //                 } else {
    //                     checkedArticles.value = checkedArticles.value + "," + item.id;                                                                       
    //                 }                                                                        
    //             }
    //         } else {
    //             if(isIn) {                    
    //                 let newVal = checkedArticles_val.replace(String(item.id), "");
    //                 const arrNew = newVal.split(",");
    //                 newVal = "";
    //                 arrNew.map((val) => {
    //                     if(val.length > 0) {
    //                         if(newVal === "") {
    //                             newVal = val;
    //                         } else {
    //                             newVal = newVal + "," + val;
    //                         }    
    //                     }
    //                 });
    //                 // logger.log("onChangeChk", e.target.checked, item, arr, isIn, newVal);
    //                 checkedArticles.value = newVal;
    //             }    
    //         }
    //     }                                                            
    // }

    return (

        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box w-avail" style={{height:"100%", minHeight:"40rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={props.onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">{title}</h2>

                            <div className="px-5 py-5">
                                    
                                <div className=""  id="subject" role="tabpanel" aria-labelledby="subject-tab"> 
                                    
                                    {/* <!-- 검색영역 --> */}
                                    <div className="px-8 py-8 border border-gray-300 rounded-xl">
                                        <div>
                                            <fieldset>
                                                <input type="radio" id="rd_select_cb" name="rd-select" className="items-center mr-2" style={{display: props.textType === "Analogous_Analogous" ? "" : "none"}} />
                                                <label htmlFor="curriBook">교과</label>
                                                <select style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} id="PCL_curriBook" name="PCL_curriBook" onChange={(e) => onChangeCurriBook(e)}>
                                                    <option value="0">교과</option>              
                                                    {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.code}>{item.val}</option>              
                                                    )})}
                                                </select>
                                            </fieldset>
                                            <fieldset>
                                                <label htmlFor="curriUnit1" className="ml-8">교과과정</label>
                                                <select className="mr-2" id="PCL_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriUnit1.length > 0 ? "" : "none", paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="0">1Depth</option>              
                                                    {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <select  className="mr-2" id="PCL_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriUnit2.length > 0 ? "" : "none", paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="0">2Depth</option>              
                                                    {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <select className="mr-2" id="PCL_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriUnit3.length > 0 ? "" : "none", paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="0">3Depth</option>              
                                                    {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <select className="mr-2" id="PCL_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriUnit4.length > 0 ? "" : "none", paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="0">4Depth</option>              
                                                    {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <select id="PCL_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriUnit5.length > 0 ? "" : "none", paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="0">5Depth</option>              
                                                    {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                            </fieldset>
                                        </div>

                                        <div className="mt-3" style={{display: props.textType === "Analogous_Analogous" ? "" : "none"}}>
                                            <fieldset>
                                                <input type="radio" id="rd_select_sm" name="rd-select" className="items-center mr-2" />
                                                <label htmlFor="curriBook">학습맵</label>
                                                <select id="PCL_studyMap" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} name="PCL_studyMap" onChange={(e) => onChangeStudyMap(e)}>
                                                    <option value="0">학습맵</option>              
                                                    {studyMap0List && studyMap0List.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                            </fieldset>
                                            <fieldset>
                                                <select className="mx-2" id="PCL_studyMap1" name="studyMap1" onChange={(e) => onChangeStudyMap1(e)} style={{display:studyMap1List.length > 0 ? "" : "none", paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="0">1Depth</option>              
                                                    {studyMap1List && (studyMap1List as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <select className="mr-2" id="PCL_studyMap2" name="studyMap2" onChange={(e) => onChangeStudyMap2(e)} style={{display:studyMap2List.length > 0 ? "" : "none", paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="0">2Depth</option>              
                                                    {studyMap2List && (studyMap2List as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <select className="mr-2" id="PCL_studyMap3" name="studyMap3" onChange={(e) => onChangeStudyMap3(e)} style={{display:studyMap3List.length > 0 ? "" : "none", paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="0">3Depth</option>              
                                                    {studyMap3List && (studyMap3List as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <select className="mr-2" id="PCL_studyMap4" name="studyMap4" onChange={(e) => onChangeStudyMap4(e)} style={{display:studyMap4List.length > 0 ? "" : "none", paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="0">4Depth</option>              
                                                    {studyMap4List && (studyMap4List as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <select id="PCL_studyMap5" name="studyMap5" style={{display:studyMap5List.length > 0 ? "" : "none", paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="0">5Depth</option>              
                                                    {studyMap5List && (studyMap5List as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                            </fieldset>
                                        </div>

                                        {/* <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch} >
                                                    <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                                                </button> */}

                                        <div className="mt-3">
                                            <fieldset>
                                                <label htmlFor="search-3">콘텐츠</label>
                                                <select style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} id="PCL_contentsType" name="search-3">
                                                    <option value={""}>전체</option>
                                                    <option value={"개념"}>개념</option>
                                                    <option value={"활동"}>활동</option>
                                                    <option value={"문항"}>문항</option>
                                                </select>
                                            </fieldset>

                                            <fieldset>
                                                <label htmlFor="search-4" className="ml-8">AIDT 공개 여부</label>
                                                <select id="PCL_public" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} name="search-4">
                                                    <option value={""}>전체</option>
                                                    <option value={"1"}>공개</option>
                                                    <option value={"0"}>비공개</option>
                                                </select>
                                            </fieldset>
                                            <fieldset>
                                                <label htmlFor="search-5" className="ml-8">검인정 심사</label>
                                                <select id="PCL_review" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} name="search-5">
                                                    <option value={""}>전체</option>
                                                    <option value={"0"}>통과</option>
                                                    <option value={"1"}>완료</option>
                                                </select>
                                            </fieldset>

                                            <fieldset>
                                                <label htmlFor="date" className="ml-8">등록기간</label>
                                            </fieldset>

                                            <fieldset>
                                                <DatePicker id='PCL_sdate' name='PCL_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                                            </fieldset>

                                            <fieldset>
                                                <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickSDateIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                                    </svg>                                            
                                                </button> 
                                            </fieldset>

                                            <fieldset>
                                                <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                                            </fieldset>

                                            <fieldset>
                                                <DatePicker id='PCL_edate' name='PCL_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                                            </fieldset>

                                            <fieldset>
                                                <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                                    </svg>                                            
                                                </button> 
                                            </fieldset>

                                            <fieldset>
                                                <label htmlFor="search-5" className="ml-8">난이도</label>
                                                <select id="PCL_difficulty" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} name="search-5">
                                                    <option value={""}>전체</option>
                                                    {curriInfo && (curriInfo.difficulty as IMeta[]).map((item, idx) => {
                                                    return(
                                                    <option key={idx} value={item.id}>{item.val}</option>              
                                                    )})}
                                                </select>
                                            </fieldset>

                                        </div>

                                        <div className="mt-3 text-right">
                                            <fieldset>
                                                <label htmlFor="search-6">검색</label>
                                                <select id="PCL_keyword_type" name="search-6">
                                                    <option value={"name"}>콘텐츠명</option>
                                                    <option value={"id"}>KEY</option>
                                                    <option value={"hashTags"}>해시 태그</option>
                                                </select>
                                                <input type="text" name="search" id="PCL_keyword" className="w-[300px] ml-3" onKeyUp={(e) => {
                                                    if (e.key === 'Enter') {
                                                        onSearch();
                                                    }
                                                }} />
                                                <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch}>
                                                    <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                                                </button>
                                            </fieldset>
                                        </div>                        
                                    </div>

                                    {/* <div className="px-8 py-8 border border-gray-300 rounded-xl">
                                        <div className="mt-3 text-right">
                                            <fieldset>
                                                <label htmlFor="search-3">콘텐츠</label>
                                                <select style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} id="PCL_contentsType" name="search-3">
                                                    <option value={""}>전체</option>
                                                    <option value={"개념"}>개념</option>
                                                    <option value={"활동"}>활동</option>
                                                    <option value={"문항"}>문항</option>
                                                </select>
                                            </fieldset>

                                            <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch} >
                                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                                            </button>
                                        </div>
                                    </div> */}

                                    {/* <!-- .//검색영역 --> */}

                                    {/* <!-- table --> */}
                                    {viewList &&
                                    <table className="mt-5">
                                        <caption className="sr-only">교과 콘텐츠 테이블</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center" style={{display: props.multiSelect && props.multiSelect === true ? "" : "none" }}>
                                                    <input id="all_check" className="h-4 w-4 rounded mt-2" type="checkbox" onClick={(e) => {
                                                        const list_ck = document.getElementsByName("list_ck");
                                                        for (let i = 0; i < list_ck.length; i++) {
                                                            const element = list_ck[i] as HTMLInputElement;
                                                            if(!element.disabled) {
                                                                element.checked = (e.target as HTMLInputElement).checked;
                                                            }                                    
                                                        }
                                                    }} />                                                    
                                                </th>
                                                <th scope="col" className="text-center px-3">번호</th>
                                                <th scope="col" className="text-center px-3">KEY</th>
                                                <th scope="col" className="text-center px-3">교과</th>
                                                {/* <th scope="col" className="text-center px-3">교육과정 표준체게</th> */}
                                                <th scope="col" className="text-center px-3">콘텐츠</th>
                                                <th scope="col" className="text-center px-3">콘텐츠 명</th>
                                                {/* <th scope="col" className="px-3">연관 라이브러리 KEY</th>
                                                <th scope="col" className="px-3">해시태그</th> */}
                                                <th scope="col" className="text-center px-3">등록일</th>
                                                {/* <th scope="col" className="px-3">수정일</th> */}
                                                {/* <th scope="col" className="px-3">미리보기</th>
                                                <th scope="col" className="px-3">편집</th>
                                                <th scope="col" className="px-3">공개 설정</th>
                                                <th scope="col" className="pl-3 pr-4">심사</th> */}
                                                <th scope="col" className="text-center px-3">확인</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        {viewList && list &&
                                            <tr style={{display: (list as IArticle[]).length === 0 ? "" : "none"}}>
                                                <td colSpan={props.multiSelect && props.multiSelect === true ? 8 : 7}>등록된 오브젝트가 없습니다.</td>
                                            </tr>}

                                        {viewList && list && (list as IArticle[]).map((item, idx) => {

                                            let _unitVal = "";
                                            let _curriBookVal = ""

                                            if(curriInfo && curriInfo.curriBook) {
                                                const curriBook = curriInfo.curriBook as IMeta[];
                                                const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                                                _curriBookVal = _curriBook ? _curriBook.val : "";
                                            }

                                            const totalCnt = item.full_count;
                                            const _numOfPage = 10;
                                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                                            let _articleTypeVal = "";
                                            if(curriInfo && curriInfo.articleType) {
                                                const articleType = curriInfo.articleType as IMeta[];
                                                const _articleType = articleType.find((article, _) => article.id === item.articleType);
                                                _articleTypeVal = _articleType ? _articleType.val : "-";
                                            }

                                            let updateDate = "-";
                                            if(item.updatedate) {
                                                updateDate = item.updatedate.substring(0, 10).replaceAll("-", ".");
                                            }
                                            
                                            let hashTag = "";
                                            const arrHashTag = item.hashTag as string[];
                                            if(arrHashTag) {
                                                arrHashTag.map((item) => {
                                                    if(item.trim() !== "") {
                                                        hashTag = hashTag + (item[0] !== "#" ? "#" : "") + item + " ";
                                                    }
                                                });    
                                            }

                                            let libraryIds = "";
                                            const libraryMap = item.libraryMap;
                                            if(libraryMap) {
                                                libraryMap.map((item) => {
                                                    if(libraryIds === "") {
                                                        libraryIds = String(item.id);
                                                    } else {
                                                        libraryIds = libraryIds + "," + item.id;
                                                    }
                                                });
                                            }
                                            
                                            const checkedArticles = document.getElementById("checkedArticles") as HTMLInputElement;
                                            if(checkedArticles) {
                                                const checkedArticles_val = checkedArticles.value;
                                                const chk_ = document.getElementById("chk_"+idx) as HTMLInputElement;
                                                if(chk_) {
                                                    const arr = checkedArticles_val.split(",");
                                                    const isIn = arr.find((_item) => _item === String(item.id));
                                                    if(isIn) {
                                                        chk_.checked = true;
                                                    } else {
                                                        chk_.checked = false;
                                                    }    
                                                }
                                            }

                                            const bFound = false;

                                            return (

                                                <tr key={idx}>
                                                    <td style={{display: props.multiSelect && props.multiSelect === true ? "" : "none" }}>
                                                        
                                                        {/* <input id={"chk_"+idx} type="checkbox" name="chk" className="h-4 w-4 rounded" onChange={(e) => onChangeChk(e, item)} /> */}

                                                        <input id={"list_ck_"+idx} name="list_ck" type="checkbox" className="h-4 w-4 rounded mt-2" defaultChecked={bFound} onClick={(e) => {
                                                            const list_ck = document.getElementsByName("list_ck");
                                                            let cntChecked = 0;
                                                            for (let i = 0; i < list_ck.length; i++) {
                                                                const element = list_ck[i] as HTMLInputElement;
                                                                if(element.checked) {
                                                                    cntChecked = cntChecked + 1;
                                                                }
                                                            }
                                                            const all_check = document.getElementById("all_check") as HTMLInputElement;
                                                            if(cntChecked === list_ck.length) {                                                
                                                                all_check.checked = true;                                                
                                                            } else {
                                                                all_check.checked = false;                                                
                                                            }
                                                        }}/>

                                                    </td>
                                                    <td>{_num}</td>
                                                    <td>{item.id}</td>
                                                    <td>{_curriBookVal}</td>
                                                    {/* <td dangerouslySetInnerHTML={{ __html: _unitVal }}></td> */}
                                                    <td>{_articleTypeVal}</td>
                                                    <td>{item.name}</td>
                                                    {/* <td>{libraryIds}</td>
                                                    <td>{hashTag}</td> */}
                                                    <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                                    {/* <td>{updateDate}</td> */}
                                                    {/* <td><button type="button" className="btn-white-s" onClick={() => onPreView(item)}>미리보기</button></td>
                                                    <td><button type="button" className="btn-white-s" disabled={item.review === 1} onClick={() => onEditPage(item)}>편집</button></td>
                                                    <td>
                                                        <label className="inp-toggle">
                                                            <input type="checkbox" id="PCL_is_publicOpen" value="" className="peer" defaultChecked={item.is_publicOpen} onChange={() => onChangePublicOpen(item)}/>
                                                            <div className="peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-sky-500"></div>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <button type="button" className={item.review === 0 ? "btn-white-s" : "btn-white-s !text-sky-500 !bg-sky-50"} onClick={() => onReview(item)}>{item.review === 0 ? "통과" : "완료"}</button>
                                                    </td> */}
                                                    <td>
                                                        <button type="button" className={item.review === 0 ? "btn-white-s" : "btn-white-s !text-sky-500 !bg-sky-50"} onClick={() => onSelect(item)}>확인</button>
                                                    </td>
                                                </tr>

                                            )
                                            })}

                                        </tbody>
                                    </table>}
                                    {/* <!-- .//table -->	 */}

                                    <div className="relative mt-5">

                                        {/* <!-- pagenation --> */}
                                        <div className="text-center">
                                            <nav className="pagenation" aria-label="Pagination">

                                                {listOfPage && (listOfPage as number[]).length > 0 &&
                                                <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                                    <span className="sr-only">Previous</span>
                                                    <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                                    </svg>
                                                </div>}

                                                {listOfPage && (listOfPage as number[]).map((item, idx) => {
                                                return (
                                                    currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                                                )})}

                                                {listOfPage && (listOfPage as number[]).length > 0 &&
                                                <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                                    <span className="sr-only">Next</span>
                                                    <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                                    </svg>
                                                </div>}
                                                
                                            </nav>
                                        </div>
                                        {/* <!-- .// pagenation --> */}
                                    </div>

                                    <div className="mt-5 text-center" style={{display: props.multiSelect && props.multiSelect === true ? "" : "none" }}>
                                        {/* <input className="w-avail mr-2" type="text" id="checkedArticles" readOnly /> */}
                                        <button type="button" className="btn-sky" onClick={() => onSelectMulti()}>불러오기</button>
                                    </div>

                                </div>

                            </div>

                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>
        </div>


    );  
}
export default PopupContentsList;
