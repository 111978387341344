import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, IUser } from "../common";  
import { logger } from "../logger";
import { IStudyMap } from "./LibraryRegister";

interface IPopupStudyMapSearch {
    onSelect: (item: IStudyMap) => void;
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function PopupStudyMapSearch(props: IPopupStudyMapSearch) {
      
    const [studyMapsList, setStudyMapsList] = useState<IMeta[]>([]);
    const [studyMap0List, setStudyMap0List] = useState<IMeta[]>([]);
    const [studyMap1List, setStudyMap1List] = useState<IMeta[]>([]);
    const [studyMap2List, setStudyMap2List] = useState<IMeta[]>([]);
    const [studyMap3List, setStudyMap3List] = useState<IMeta[]>([]);
    const [studyMap4List, setStudyMap4List] = useState<IMeta[]>([]);
    const [studyMap5List, setStudyMap5List] = useState<IMeta[]>([]);
    const [studyMap6List, setStudyMap6List] = useState<IMeta[]>([]);
    const [studyMap7List, setStudyMap7List] = useState<IMeta[]>([]);
    const [studyMap8List, setStudyMap8List] = useState<IMeta[]>([]);
    const [studyMap9List, setStudyMap9List] = useState<IMeta[]>([]);
    const [studyMap10List, setStudyMap10List] = useState<IMeta[]>([]);

    const [studyMapDepth, setStudyMapDepth] = useState(0);


    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            setStudyMapDepth(0);

            getStudyMapList();

        }
        bDone = true;

    }, [])

    async function getStudyMapList() {

        props.showLoading(true);


        const names = [
            "studyMap",
        ];

        const listAll = await util.getMetaNamesList(names);

        const _studyMap = listAll.filter((meta, _) => meta.name === "studyMap");

        setStudyMap0List(_studyMap);

        logger.log("getStudyMapsList", listAll);

        props.showLoading(false);
    }

    async function getStudyMapDescendantsList(code: string) {

        props.showLoading(true);

        const list = await util.getDescendantsList(code);

        const _studyMap = list.find((item) => item.code === code);
        if(!_studyMap) {
            return;
        }
        setStudyMapsList(list);

        const _filtered_1 = list.filter((item) => item.name === "studyMap1");
        setStudyMap1List(_filtered_1);

        let max_depth = 0;
        if(_studyMap.max_depth) {
            max_depth = _studyMap.max_depth;
        } else {
            list.map((item) => {
                if(max_depth < item.depth) {
                    max_depth = item.depth;
                }
            });
            max_depth = max_depth - 1;
        }
        setStudyMapDepth(max_depth);        

        logger.log("getStudyMapDescendantsList", list, max_depth);

        props.showLoading(false);
    }

    async function onChangeStudyMap(e: ChangeEvent<HTMLSelectElement>) {
        if(e.target.selectedIndex > 0) {
            const _studyMap = studyMap0List[e.target.selectedIndex-1];
            logger.log("onChangeStudyMap", _studyMap);
    
            getStudyMapDescendantsList(_studyMap.code);    
        } else {
            setStudyMap1List([]);
        }

        setStudyMap2List([]);
        setStudyMap3List([]);
        setStudyMap4List([]);
        setStudyMap5List([]);
        setStudyMap6List([]);

    }

    async function onChangeStudyMap1(e: ChangeEvent<HTMLSelectElement>) {
        // const _filtered_2 = (studyMapsList as IMeta[]).filter((item) => item.depth === 3);
        // logger.log("onChangeStudyMap1", _filtered_2);

        const result = studyMapsList.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap2List(result);
        logger.log("onChangeStudyMap1", e.target.value, result);

        setStudyMap3List([]);
        setStudyMap4List([]);
        setStudyMap5List([]);
        setStudyMap6List([]);
    }

    async function onChangeStudyMap2(e: ChangeEvent<HTMLSelectElement>) {
        // const _filtered_3 = (studyMapsList as IMeta[]).filter((item) => item.depth === 4);
        // logger.log("onChangeStudyMap2", _filtered_3);

        const result = studyMapsList.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap3List(result);
        logger.log("onChangeStudyMap2", e.target.value, result);

        setStudyMap4List([]);
        setStudyMap5List([]);
        setStudyMap6List([]);
    }
    async function onChangeStudyMap3(e: ChangeEvent<HTMLSelectElement>) {
        // const _filtered_4 = (studyMapsList as IMeta[]).filter((item) => item.depth === 5);
        // logger.log("onChangeStudyMap3", _filtered_4);

        const result = studyMapsList.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap4List(result);
        logger.log("onChangeStudyMap3", e.target.value, result);

        setStudyMap5List([]);
        setStudyMap6List([]);
    }
    async function onChangeStudyMap4(e: ChangeEvent<HTMLSelectElement>) {
        // const _filtered_5 = (studyMapsList as IMeta[]).filter((item) => item.depth === 6);
        // logger.log("onChangeStudyMap4", _filtered_5);

        const result = studyMapsList.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap5List(result);
        logger.log("onChangeStudyMap4", e.target.value, result);

        setStudyMap6List([]);
    }
    async function onChangeStudyMap5(e: ChangeEvent<HTMLSelectElement>) {
        // const _filtered_6 = (studyMapsList as IMeta[]).filter((item) => item.depth === 7);
        // logger.log("onChangeStudyMap5", _filtered_6);

        const result = studyMapsList.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap6List(result);
        logger.log("onChangeStudyMap5", e.target.value, result);
    }
    async function onChangeStudyMap6(e: ChangeEvent<HTMLSelectElement>) {
    }

    function onClose() {
        props.onClose();
    }

    function onSelect() {

        const SM_Depth0 = document.getElementById("SM_Depth0") as HTMLSelectElement;
        const SM_Depth1 = document.getElementById("SM_Depth1") as HTMLSelectElement;
        const SM_Depth2 = document.getElementById("SM_Depth2") as HTMLSelectElement;
        const SM_Depth3 = document.getElementById("SM_Depth3") as HTMLSelectElement;
        const SM_Depth4 = document.getElementById("SM_Depth4") as HTMLSelectElement;
        const SM_Depth5 = document.getElementById("SM_Depth5") as HTMLSelectElement;
        const SM_Depth6 = document.getElementById("SM_Depth6") as HTMLSelectElement;
    
        const SM_Depth0_val = studyMap0List[SM_Depth0.selectedIndex-1];
        const SM_Depth1_val = studyMap1List[SM_Depth1.selectedIndex-1];
        const SM_Depth2_val = studyMap2List[SM_Depth2.selectedIndex-1];
        const SM_Depth3_val = studyMap3List[SM_Depth3.selectedIndex-1];
        const SM_Depth4_val = studyMap4List[SM_Depth4.selectedIndex-1];
        const SM_Depth5_val = studyMap5List[SM_Depth5.selectedIndex-1];
        const SM_Depth6_val = studyMap6List[SM_Depth6.selectedIndex-1];
        
        const data : IStudyMap = {
            id: 1,
            depth0: SM_Depth0_val,
            depth1: SM_Depth1_val,
            depth2: SM_Depth2_val,
            depth3: SM_Depth3_val,
            depth4: SM_Depth4_val,
            depth5: SM_Depth5_val,
            depth6: SM_Depth6_val,
        }
        props.onSelect(data);
    }

    return (
                
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{width:"60rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">학습맵 선택</h2>
                            <div className="px-5">
                                <div className="py-8 text-center">

                                    <table>
                                        <caption className="sr-only">관리 테이블</caption>
                                        <tbody>

                                        <tr>
                                                <td className="text-left">
                                                    <div>
                                                        <select className="mr-2" id="SM_Depth0" name="SM_Depth0" onChange={(e) => onChangeStudyMap(e)}>
                                                            <option value={""}>학습맵</option>
                                                            {studyMap0List && studyMap0List.map((sm0, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm0.val}>{sm0.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="mt-2">
                                                        <select className="mr-2" id="SM_Depth1"  onChange={(e) => onChangeStudyMap1(e)}>
                                                            <option value={""}>Depth 1</option>
                                                            {studyMap1List && studyMap1List.map((sm, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm.id}>{sm.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <select className="mr-2" id="SM_Depth2"  onChange={(e) => onChangeStudyMap2(e)}>
                                                            <option value={""}>Depth 2</option>
                                                            {studyMap2List && studyMap2List.map((sm, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm.id}>{sm.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <select className="mr-2" id="SM_Depth3"  onChange={(e) => onChangeStudyMap3(e)}>
                                                            <option value={""}>Depth 3</option>
                                                            {studyMap3List && studyMap3List.map((sm, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm.id}>{sm.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="mt-2">
                                                        <select className="mr-2" id="SM_Depth4"  onChange={(e) => onChangeStudyMap4(e)}>
                                                            <option value={""}>Depth 4</option>
                                                            {studyMap4List && studyMap4List.map((sm, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm.id}>{sm.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <select className="mr-2" id="SM_Depth5"  onChange={(e) => onChangeStudyMap5(e)}>
                                                            <option value={""}>Depth 5</option>
                                                            {studyMap5List && studyMap5List.map((sm, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm.id}>{sm.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <select className="mr-2" id="SM_Depth6"  onChange={(e) => onChangeStudyMap6(e)}>
                                                            <option value={""}>Depth 6</option>
                                                            {studyMap6List && studyMap6List.map((sm, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm.id}>{sm.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>   

                                        </tbody>
                                    </table>
                                    
                                </div>

                                <div className="my-5 text-right">
                                    <button type="button" className="btn-sky" onClick={onSelect}>저장</button>
                                </div>

                            </div>
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>
        </div>

    );
}
  
export default PopupStudyMapSearch;
