import { useEffect, useState } from "react";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ILibrary, IMenu, IMeta, ITextBook } from "../common";
import { logger } from '../logger';
import PopupLibrarySearch from "../library/PopupLibrarySearch";

interface ITeachingEdit {
    brand: IBrand;
    menu: IMenu;
    item: ITextBook | null;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
    onClose: () => void;
}
  
function TeachingEdit(props: ITeachingEdit) {
    
    const [version, setVersion] = useState(1);
    const [lastUpdateDate, setLastUpdateDate] = useState("");

    const [isNext, setIsNext] = useState(false);

    const [teachingCategoryList, setTeachingCategoryList] = useState(null as any);
    const [teachingUsageList, setTeachingUsageList] = useState(null as any);
    const [teachingSubjectList, setTeachingSubjectList] = useState(null as any);

    const [teachingCurriBookList, setTeachingCurriBookList] = useState(null as any);
    const [viewSearchObject, setViewSearchObject] = useState(false);

    const currMenu = util.getCurrentMenu();

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            getTeachingInfo();

            setLastUpdateDate(util.getDate());

            setVersion(1);

            setTeachingCurriBookList(curriInfo?.curriBook);

            const TE_non_active = (document.getElementById("TE_non_active") as HTMLInputElement);
            if(TE_non_active) {
                TE_non_active.checked = true;
            }

            const TE_title = document.getElementById("TE_title") as HTMLInputElement;
            if(TE_title) {
                TE_title.focus();
            }

            const item = props.item;
            if(item) {
                logger.log("props.item -> ", item);
                TE_title.value = item.name;

                if(item.version && item.version > 1) {
                    setVersion(item.version);
                }

                const TE_is_active = (document.getElementById("TE_is_active") as HTMLInputElement);
                TE_is_active.checked = item.is_active;

                const TE_non_active = (document.getElementById("TE_non_active") as HTMLInputElement);
                TE_non_active.checked = !item.is_active;
            }
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(teachingCategoryList && teachingUsageList && teachingSubjectList) {
            if(props.item) {
                getTextBookInfo(props.item.id!);
            }
        }
    }, [teachingCategoryList, teachingUsageList, teachingSubjectList])

    useEffect(() => {
        if(teachingCurriBookList) {
            if(props.item) {
                logger.log("teachingCurriBookList", teachingCurriBookList);
            }
        }
    }, [teachingCurriBookList])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            // logger.log("props.brand", props.brand);
        }
        preBrand = props.brand;
    }, [props.brand])

    async function getTeachingInfo() {
        const teachingCategory = await util.getMetaList("1", "teachingCategory");
        if(teachingCategory) {
            const _teachingCategory = teachingCategory.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingCategoryList(_teachingCategory);    
        }
        const teachingUsage = await util.getMetaList("1", "teachingUsage");
        if(teachingUsage) {
            const _teachingUsage = teachingUsage.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingUsageList(_teachingUsage);    
        }
        const teachingSubject = await util.getMetaList("1", "teachingSubject");
        if(teachingSubject) {
            const _teachingSubject = teachingSubject.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingSubjectList(_teachingSubject);    
        }

        logger.log("getTeachingInfo", teachingCategory, teachingUsage, teachingSubject);
    }

    async function getTextBookInfo(id: number) {
        
        const list = await util.getTextBookInfo(id);
        logger.log("getTextBookInfo", list);

        if(list) {
            const metaMap = (list as ITextBook).metaMap;

            const curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
            if(curriYear) {
                const _curriYear = metaMap.find((meta, _) => meta.name === "curriYear");
                if(_curriYear) {
                    curriYear.value = _curriYear.val;
                }    
            }
            const curriSchool = document.getElementById("TE_curriSchool") as HTMLSelectElement;
            if(curriSchool) {
                const _curriSchool = metaMap.find((meta, _) => meta.name === "curriSchool");
                if(_curriSchool) {
                    curriSchool.value = _curriSchool.val;
                }    
            }
            const curriSubject = document.getElementById("TE_curriSubject") as HTMLSelectElement;
            if(curriSubject) {
                const _curriSubject = metaMap.find((meta, _) => meta.name === "curriSubject");
                if(_curriSubject) {
                    curriSubject.value = _curriSubject.val;
                }
            }
            const TE_category = document.getElementById("TE_Category") as HTMLSelectElement;
            if(TE_category) {
                const _teachingCategory = metaMap.find((meta, _) => meta.name === "teachingCategory");
                if(_teachingCategory) {
                    TE_category.value = _teachingCategory.val;
                }
            }                
            const TE_usage = document.getElementById("TE_Usage") as HTMLSelectElement;
            if(TE_usage) {
                const _teachingUsage = metaMap.find((meta, _) => meta.name === "teachingUsage");
                if(_teachingUsage) {
                    TE_usage.value = _teachingUsage.val;
                }
            }    
            const TE_curriSubject = document.getElementById("TE_teachingSubject") as HTMLSelectElement;
            if(TE_curriSubject) {
                const _teachingSubject = metaMap.find((meta, _) => meta.name === "teachingSubject");
                if(_teachingSubject) {
                    TE_curriSubject.value = _teachingSubject.val;
                }
            }   
            const TE_curriGrade = document.getElementById("TE_curriGrade") as HTMLSelectElement;
            if(TE_curriGrade) {
                const _curriGrade = metaMap.find((meta, _) => meta.name === "curriGrade");
                if(_curriGrade) {
                    TE_curriGrade.value = _curriGrade.val;
                }
            }   
            
            const _teachingCurriBookList = await onChangeForCurriBook();

            setTimeout(() => {
                const curriBook = document.getElementById("TE_curriBook") as HTMLSelectElement;
                if(curriBook) {
                    const _curriBook = metaMap.find((meta, _) => meta.name === "curriBook");
                    if(_curriBook) {
                        let _teachingCurriBook = null;
                        if(_teachingCurriBookList) {
                            _teachingCurriBook = (_teachingCurriBookList as IMeta[]).find((meta, _) => meta.id === _curriBook.id);
                            logger.log("_curriBook", _curriBook, _teachingCurriBook, teachingCurriBookList);    
                        }
                        if(_teachingCurriBook) {
                            curriBook.value = _curriBook.val;
                        } else {

                            curriBook.selectedIndex = 0;

                            // if(curriInfo && curriInfo.curriBook) {
                            //     let num = -1;
                            //     for (let i = 0; i < curriInfo.curriBook.length; i++) {
                            //         const curriBook = curriInfo.curriBook[i];
                            //         if(curriBook.id === _curriBook.id) {
                            //             num = i - 1;
                            //             break;
                            //         }                                    
                            //     }

                            //     logger.log("num", num);

                            //     if(num > -1 && _teachingCurriBookList) {
                            //         const _teachingCurriBook = _teachingCurriBookList[num] as IMeta;
                            //         if(_teachingCurriBook) {
                            //             curriBook.value = _teachingCurriBook.val;

                            //             _onNext(_teachingCurriBookList);
                            //         }                                    
                            //     }
                            // }
                        }
                    }
                }                    
            }, 100);
        }
    }
    
    async function onNext() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        const TE_title = document.getElementById("TE_title") as HTMLInputElement;
        if(TE_title.value === "") {
            props.showModal("", "제목을 입력해 주세요.");
            TE_title.focus();
            return;
        }

        const metaMap : IMeta[] = [];

        let TE_curriYear_val = undefined;
        const TE_curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
        if(TE_curriYear) {
            if(TE_curriYear.selectedIndex > 0) {
                TE_curriYear_val = curriInfo.curriYear[TE_curriYear.selectedIndex-1];
                metaMap.push(TE_curriYear_val);    
            }
        }
        let TE_curriSchool_val = undefined;
        const TE_curriSchool = document.getElementById("TE_curriSchool") as HTMLSelectElement;
        if(TE_curriSchool) {
            if(TE_curriSchool.selectedIndex > 0) {
                TE_curriSchool_val = curriInfo.curriSchool[TE_curriSchool.selectedIndex-1];
                metaMap.push(TE_curriSchool_val);
            }
        }
        let TE_curriSubject_val = undefined;
        const TE_curriSubject = document.getElementById("TE_curriSubject") as HTMLSelectElement;
        if(TE_curriSubject) {
            if(TE_curriSubject.selectedIndex > 0) {
                TE_curriSubject_val = curriInfo.curriSubject[TE_curriSubject.selectedIndex-1];
                metaMap.push(TE_curriSubject_val);
            }
        }
        let TE_category_val = undefined;
        const TE_category = document.getElementById("TE_Category") as HTMLSelectElement;
        if(TE_category) {
            if(TE_category.selectedIndex > 0) {
                TE_category_val = teachingCategoryList[TE_category.selectedIndex-1];
                metaMap.push(TE_category_val);
            }
        }
        let TE_usage_val = undefined;
        const TE_usage = document.getElementById("TE_Usage") as HTMLSelectElement;
        if(TE_usage) {
            if(TE_usage.selectedIndex > 0) {
                TE_usage_val = teachingUsageList[TE_usage.selectedIndex-1];
                metaMap.push(TE_usage_val);
            }
        }
        let TE_curriBook_val = undefined;
        const TE_curriBook = document.getElementById("TE_curriBook") as HTMLSelectElement;
        if(TE_curriBook) {
            if(TE_curriBook.selectedIndex > 0) {
                TE_curriBook_val = teachingCurriBookList[TE_curriBook.selectedIndex-1];
                metaMap.push(TE_curriBook_val);
            }
        }
        let TE_teachingSubject_val = undefined;
        const TE_teachingSubject = document.getElementById("TE_teachingSubject") as HTMLSelectElement;
        if(TE_teachingSubject) {
            if(TE_teachingSubject.selectedIndex > 0) {
                TE_teachingSubject_val = teachingSubjectList[TE_teachingSubject.selectedIndex-1];
                metaMap.push(TE_teachingSubject_val);
            }
        }
        let TE_curriGrade_val = undefined;
        const TE_curriGrade = document.getElementById("TE_curriGrade") as HTMLSelectElement;
        if(TE_curriGrade) {
            if(TE_curriGrade.selectedIndex > 0) {
                TE_curriGrade_val = curriInfo.curriGrade[TE_curriGrade.selectedIndex-1];
                metaMap.push(TE_curriGrade_val);
            }
        }

        const TE_is_active = (document.getElementById("TE_is_active") as HTMLInputElement);

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const data = {
            type: "teaching",
            brand_id: brand.id,
            id: props.item ? props.item.id : undefined,
            name: TE_title.value,

            creator: uid,
            creator_id: _id,
            creator_name: uname,

            data_type: TE_category_val ? TE_category_val.val : TE_category_val,

            // curriSubject: TE_curriSubject_val ? TE_curriSubject_val.id : TE_curriSubject_val,

            is_active: TE_is_active.checked,
            
            metaMap: metaMap,
        };

        props.showLoading(true);

        const result = await util.textbookForSave(data, props.item ? "update" : "insert");
        logger.log("onNext", data, result);   

        // logger.log("onNext", data);   
        // const result = null;

        // alert("새창으로 띄울 경로가 필요합니다.");

        if(result) {

            if(!props.item) {
                const item = result as ITextBook;
            
                // const textbook_id = props.item ? props.item.id : item.id;
                // const web_textbook_id = props.item ? props.item.web_textbook_id : item.web_textbook_id;
                const textbook_id = item.id;
                const web_textbook_id = item.web_textbook_id;
    
                let url = "https://ibook.vivasam.com" + "/ebookeditor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                +"&textbook_id="+textbook_id
                +"&web_textbook_id="+(web_textbook_id ? web_textbook_id : "")
                +"&type=teaching"
                +"&token="+util.getUserInfo("token");
    
                // const url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                // +"&create=article"+"&token="+util.getUserInfo("token");
    
                window.open(url, "_blank", "noopener, noreferrer");            
            }
        }

        props.showLoading(false);

        props.onClose();
    }

    async function _onNext(_teachingCurriBookList: IMeta[]) {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        const TE_title = document.getElementById("TE_title") as HTMLInputElement;
        if(TE_title.value === "") {
            props.showModal("", "제목을 입력해 주세요.");
            TE_title.focus();
            return;
        }

        const metaMap : IMeta[] = [];

        let TE_curriYear_val = undefined;
        const TE_curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
        if(TE_curriYear) {
            if(TE_curriYear.selectedIndex > 0) {
                TE_curriYear_val = curriInfo.curriYear[TE_curriYear.selectedIndex-1];
                metaMap.push(TE_curriYear_val);    
            }
        }
        let TE_curriSchool_val = undefined;
        const TE_curriSchool = document.getElementById("TE_curriSchool") as HTMLSelectElement;
        if(TE_curriSchool) {
            if(TE_curriSchool.selectedIndex > 0) {
                TE_curriSchool_val = curriInfo.curriSchool[TE_curriSchool.selectedIndex-1];
                metaMap.push(TE_curriSchool_val);
            }
        }
        let TE_curriSubject_val = undefined;
        const TE_curriSubject = document.getElementById("TE_curriSubject") as HTMLSelectElement;
        if(TE_curriSubject) {
            if(TE_curriSubject.selectedIndex > 0) {
                TE_curriSubject_val = curriInfo.curriSubject[TE_curriSubject.selectedIndex-1];
                metaMap.push(TE_curriSubject_val);
            }
        }
        let TE_category_val = undefined;
        const TE_category = document.getElementById("TE_Category") as HTMLSelectElement;
        if(TE_category) {
            if(TE_category.selectedIndex > 0) {
                TE_category_val = teachingCategoryList[TE_category.selectedIndex-1];
                metaMap.push(TE_category_val);
            }
        }
        let TE_usage_val = undefined;
        const TE_usage = document.getElementById("TE_Usage") as HTMLSelectElement;
        if(TE_usage) {
            if(TE_usage.selectedIndex > 0) {
                TE_usage_val = teachingUsageList[TE_usage.selectedIndex-1];
                metaMap.push(TE_usage_val);
            }
        }
        let TE_curriBook_val = undefined;
        const TE_curriBook = document.getElementById("TE_curriBook") as HTMLSelectElement;
        if(TE_curriBook) {
            if(TE_curriBook.selectedIndex > 0) {
                TE_curriBook_val = _teachingCurriBookList[TE_curriBook.selectedIndex-1];
                metaMap.push(TE_curriBook_val);
            }
        }
        let TE_teachingSubject_val = undefined;
        const TE_teachingSubject = document.getElementById("TE_teachingSubject") as HTMLSelectElement;
        if(TE_teachingSubject) {
            if(TE_teachingSubject.selectedIndex > 0) {
                TE_teachingSubject_val = teachingSubjectList[TE_teachingSubject.selectedIndex-1];
                metaMap.push(TE_teachingSubject_val);
            }
        }
        let TE_curriGrade_val = undefined;
        const TE_curriGrade = document.getElementById("TE_curriGrade") as HTMLSelectElement;
        if(TE_curriGrade) {
            if(TE_curriGrade.selectedIndex > 0) {
                TE_curriGrade_val = curriInfo.curriGrade[TE_curriGrade.selectedIndex-1];
                metaMap.push(TE_curriGrade_val);
            }
        }

        const TE_is_active = (document.getElementById("TE_is_active") as HTMLInputElement);

        const data = {
            type: "teaching",
            brand_id: brand.id,
            id: props.item ? props.item.id : undefined,
            name: TE_title.value,

            data_type: TE_category_val ? TE_category_val.val : TE_category_val,

            // curriSubject: TE_curriSubject_val ? TE_curriSubject_val.id : TE_curriSubject_val,

            is_active: TE_is_active.checked,
            
            metaMap: metaMap,
        };

        props.showLoading(true);

        // const result = await util.textbookForSave(data, props.item ? "update" : "insert");
        // logger.log("onNext", data, result);   

        logger.log("onNext", data);   

        // alert("새창으로 띄울 경로가 필요합니다.");

        props.showLoading(false);
    }

    async function onChangeForCurriBook() {
        
        const brand = util.getCurrentBrand();

        const metaMap: {"name": string, "id": number}[] = [];

        const data = {
            "brand_id": brand?.id,
            "name":"curriBook",
            "metaMap": metaMap,
        }

        if(curriInfo) {

            const TE_curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
            if(TE_curriYear) {
                const _curriYear = curriInfo.curriYear.find((meta) => meta.val === TE_curriYear.value)
                if(_curriYear) {
                    metaMap.push({"name":"curriYear", "id":Number(_curriYear.id)});
                }
            }
            const TE_curriSchool = document.getElementById("TE_curriSchool") as HTMLSelectElement;
            if(TE_curriSchool) {
                const _curriSchool = curriInfo.curriSchool.find((meta) => meta.val === TE_curriSchool.value)
                if(_curriSchool) {
                    metaMap.push({"name":"curriSchool", "id":Number(_curriSchool.id)});
                }
            }
            const TE_curriSubject = document.getElementById("TE_curriSubject") as HTMLSelectElement;
            if(TE_curriSubject) {
                const _curriSubject = curriInfo.curriSubject.find((meta) => meta.val === TE_curriSubject.value)
                if(_curriSubject) {
                    metaMap.push({"name":"curriSubject", "id":Number(_curriSubject.id)});
                }
            }
            const TE_curriGrade = document.getElementById("TE_curriGrade") as HTMLSelectElement;
            if(TE_curriGrade) {
                const _curriGrade = curriInfo.curriGrade.find((meta) => meta.val === TE_curriGrade.value)
                if(_curriGrade) {
                    metaMap.push({"name":"curriGrade", "id":Number(_curriGrade.id)});
                }
            }
        }

        data.metaMap = metaMap;
        
        props.showLoading(true);

        const list = await util.searchMeta(data);
        if(list) {
            setTeachingCurriBookList(list);
        }

        logger.log("onChangeForCurriBook", data, curriInfo?.curriBook, list);

        props.showLoading(false);

        return list;
    }

    function onSearchLibrary() {
        setViewSearchObject(true);
    }

    function onSelectObject(item: any) {
        const media = item as ILibrary;
        logger.log("onSelectObject", media);
        setViewSearchObject(false);

        const library = (document.getElementById("library") as HTMLInputElement);
        if(library) {
            library.value = "" + media.id;
        }
    }

    async function onBulkUpdate() {

        const data = {

            brand_id: 6,

            type: "teaching",

            reg_sdate: "2024-11-26",  
            reg_edate: "2024-12-24",



            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10000,
        };

        props.showLoading(true);
        
        let listIBook = await util.getTextbookSearch(data);
        if(!listIBook) {
            listIBook = [];
        }

        props.showLoading(false);

        logger.log("onBulkUpdate", listIBook);

        // for (let i = 0; i < listIBook.length; i++) {
        for (let i = 0; i < listIBook.length; i++) {

            const iBook = listIBook[i];

            logger.log("START!!!", iBook.id, iBook);

            const description = iBook.description ? iBook.description : "";
            const arrDesc = description.split("#^|");
            if(arrDesc.length > 1) {
                logger.log("END!!! 이미 처리됨", iBook.id);
                continue;
            }

            // const iBookid = Number(iBook.id);
            // if(iBookid > 3224) {
            //     logger.log("END!!! 3224 보다 커서 이미 처리됨", iBook.id);
            //     continue;
            // }

            // logger.log("arrDesc", arrDesc, iBookid);

            const info = await util.getTextBookInfo(Number(iBook.id));
            // logger.log("getTextBookInfo", info);
    
            if(info) {
                const metaMap = (info as ITextBook).metaMap;
                logger.log("getTextBookInfo metaMap", metaMap);

                const _curriBook = metaMap.find((meta, _) => meta.name === "curriBook");

                if(_curriBook) {
                    
                    const newMM = [];

                    const _curriYear = metaMap.find((meta) => meta.name === "curriYear");
                    if(_curriYear) {
                        newMM.push(_curriYear);
                    }
                    const _curriSchool = metaMap.find((meta) => meta.name === "curriSchool");
                    if(_curriSchool) {
                        newMM.push(_curriSchool);
                    }
                    const _curriSubject = metaMap.find((meta) => meta.name === "curriSubject");
                    if(_curriSubject) {
                        newMM.push(_curriSubject);
                    }
                    const _curriGrade = metaMap.find((meta) => meta.name === "curriGrade");
                    if(_curriGrade) {
                        newMM.push(_curriGrade);
                    }
    
                    const data = {
                        "brand_id": 6,
                        "name":"curriBook",
                        "metaMap": newMM,
                    }
                                    
                    const _teachingCurriBookList = await util.searchMeta(data);
        
                    if(curriInfo && curriInfo.curriBook) {
                        let num = -1;
                        for (let i = 0; i < curriInfo.curriBook.length; i++) {
                            const curriBook = curriInfo.curriBook[i];
                            if(curriBook.id === _curriBook.id) {
                                num = i;
                                break;
                            }                                    
                        }
    
                        // logger.log("num", num);
    
                        if(num > -1 && _teachingCurriBookList) {
                            const _teachingCurriBook = _teachingCurriBookList[num] as IMeta;
                            if(_teachingCurriBook) {    

                                const newMetaMap : IMeta[] = [];
                                metaMap.map((item) => {
                                    if(item.name === "curriBook") {
                                        newMetaMap.push(_teachingCurriBook);
                                    } else {
                                        newMetaMap.push(item);
                                    }
                                });

                                let description = iBook.description ? iBook.description : "";

                                const data = {
                                    id: iBook.id,                                    
                                    metaMap: newMetaMap,
                                    description: description + "#^|" + _curriBook.id + "#^|" + _teachingCurriBook.id,
                                };
                                                
                                const result = await util.textbookForSave(data, "update");
                                logger.log("END!!!", data, result);   

                                // logger.log("END!!!", data, "num", num);                       
                            }                                    
                        }
                    }
                }       
                else {
                    logger.log("END!!! 등록된 교과서 없슴", iBook.id);  
                }
            }    
        }        
    }

    return (
        <div>

            {/* <button type="button" className="btn-sky" onClick={onBulkUpdate}>일괄수정</button> */}

            {!isNext && <>
            <div className="top-title">
                <h3>i-book 등록</h3>
            </div>

            <h4>i-book 설정</h4>
            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">i-book 등록</caption>
                <tbody>
                    <tr>
                        <th scope="row" className="w-56">버전</th>
                        <td className="text-left" colSpan={2}>{version} <span className="text-red ml-5">{lastUpdateDate} 최종 수정</span></td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-title">제목<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left" colSpan={2}>
                            <input type="text" id="TE_title" className="w-full" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-process">교육과정</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_curriYear" name="search-3" onChange={onChangeForCurriBook}>
                                <option value="">교육과정</option>
                                {curriInfo && curriInfo?.curriYear && (curriInfo?.curriYear as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">학교급</label></th>
                        <td className="text-left">
                            <select id="TE_curriSchool" name="search-4" onChange={onChangeForCurriBook}>
                                <option value="">학교급</option>
                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">학년</label></th>
                        <td className="text-left">
                            <select id="TE_curriGrade" name="search-4" onChange={onChangeForCurriBook}>
                                <option value="">학년</option>
                                {curriInfo && curriInfo?.curriGrade && (curriInfo?.curriGrade as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">교과</label></th>
                        <td className="text-left">
                            <select id="TE_curriSubject" name="search-4" onChange={onChangeForCurriBook}>
                                <option value="">교과</option>
                                {curriInfo && curriInfo?.curriSubject && (curriInfo?.curriSubject as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr style={{display: "none"}}>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">과목</label></th>
                        <td className="text-left">
                            <select id="TE_teachingSubject" name="search-4">
                                <option value="">교수자료 과목</option>
                                {teachingSubjectList && (teachingSubjectList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">교과서</label></th>
                        <td className="text-left">
                            <select id="TE_curriBook" name="search-4">
                                <option value="">교과서</option>
                                {teachingCurriBookList && (teachingCurriBookList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-type">교수자료 유형</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_Category" name="sel-type">
                                <option value="">교수자료 유형</option>
                                {teachingCategoryList && (teachingCategoryList as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-type">교수자료 용도</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_Usage" name="sel-type">
                                <option value="">교수자료 용도</option>
                                {teachingUsageList && (teachingUsageList as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>

                    <tr style={{display: "none"}}>
                        <th scope="row" className="w-56"><label htmlFor="sel-type">라이브러리 연동</label></th>
                        <td className="text-left" colSpan={2}>
                            <input id="library" type="text" className="w-96 mr-2" readOnly />
                            {/* <span id="LR_image_icon" className="icon-img block w-6 h-6 mx-3"></span> */}
                            <button type="button" className="btn-sky-s" onClick={() => onSearchLibrary()}>검색</button>
                        </td>
                    </tr>

                    <tr>
                        <th scope="col" className="w-56">사용 여부</th>
                        <td className="text-left text-slate-950 text-sm">
                            <div className="radio-wrap">
                                <span className="inp-radio text-sky-500">
                                    <input id="TE_is_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-on">사용</label>
                                </span>
                                <span className="inp-radio text-red-600">
                                    <input id="TE_non_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-off">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
            
            <div className="flex justify-between mt-5">
                <button type="button" className="btn-white" onClick={props.onClose}>취소</button>
                <button type="button" className="btn-sky" onClick={onNext}>{props.item ? "저장" : "다음"}</button>
            </div></>}

            {viewSearchObject &&
            <PopupLibrarySearch brand={props.brand} from={"Teaching"} searchType={"media"} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectObject} onClose={function (): void {
                setViewSearchObject(false);
            } } />}

        </div>

    );  
}
export default TeachingEdit;
