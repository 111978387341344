import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IArticle, IBrand, ICurriInfo, ILibrary, ILibraryFile, IMeta, IScript, IText } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import * as XLSX from 'xlsx';
import { IStudyMap } from "./LibraryRegister";

interface ILibraryBulkRegister {
    type: string;
    brand: IBrand;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function LibraryBulkRegister(props: ILibraryBulkRegister) {

    const [fileContent, setFileContent] = useState(null as any);
    const [fileUploading, setFileUploading] = useState(false);

    let prevView = false;
    
    const currMenu = util.getCurrentMenu();

    useEffect(() => {
        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.OnClose();
        //     return;
        // }    
    }, [])

    useEffect(() => {
        logger.log("props.type", props.type);
    }, [props.type])

    function OnClose(): void {
        props.OnClose();
    }

    const listLangs : string[] = ["영어", "한국어", "일본어", "중국어 간체", "중국어 번체", "베트남어", "스페인어", "포르투갈어", "아랍어"];
    const listLangsKey : string[] = ["eng", "kor", "jpn", "ch1", "ch2", "vtn", "spa", "por", "ara"];

    async function insertMultiLang(data : any) {
        props.showLoading(true);
        const result = await util.subtitleForSave(data, "auto");
        // logger.log("insertMultiLang", data, result);
        props.showLoading(false);
    }

    async function insertSentenceForScript(item: string[]) {

        const _item = item;

        const listMetaMap : IMeta[] = [];
        listMetaMap.push({id: Number(_item[6])} as IMeta);
        listMetaMap.push({id: Number(_item[7])} as IMeta);
        listMetaMap.push({id: Number(_item[53])} as IMeta);

        const examList : IText[] = [];
        // const exams = _item[42];
        // const examList : IText[] = [];
        // if(exams && exams.length > 1) {
        //     const arrExams = exams.split(",");
        //     if(arrExams.length > 0) {
        //         arrExams.map((item) => {
        //             examList.push({id: Number(item)} as IText);
        //         });                                
        //     }
        // }

        // const articles = _item[51];
        // const articleList : IArticle[] = [];
        // if(articles && articles.length > 1) {
        //     const arrArticles = articles.split(",");
        //     if(arrArticles.length > 0) {
        //         arrArticles.map((item) => {
        //             articleList.push({id: Number(item)} as IArticle);
        //         });                                
        //     }
        // }

        const studyMapList : IStudyMap[] = [];

        // for (let i = 54; i < 64; i++) {
        //     const sm = _item[i];
        //     if(sm && sm.length > 1) {
        //         const arrSM = sm.split(",");
        //         if(arrSM.length > 0) {

        //             const studyMap : IStudyMap = {
        //                 depth0: { id: Number(arrSM[0]) } as IMeta,
        //                 depth1: { id: Number(arrSM[1]) } as IMeta,
        //                 depth2: { id: Number(arrSM[2]) } as IMeta,
        //                 depth3: { id: Number(arrSM[3]) } as IMeta,
        //                 depth4: { id: Number(arrSM[4]) } as IMeta,
        //                 depth5: { id: Number(arrSM[5]) } as IMeta,
        //                 depth6: { id: Number(arrSM[6]) } as IMeta,
        //                 id: 0,
        //             }

        //             if(arrSM[0]) {
        //                 listMetaMap.push({ id: Number(arrSM[0]) } as IMeta);
        //             }
        //             if(arrSM[1]) {
        //                 listMetaMap.push({ id: Number(arrSM[1]) } as IMeta);
        //             }
        //             if(arrSM[2]) {
        //                 listMetaMap.push({ id: Number(arrSM[2]) } as IMeta);
        //             }
        //             if(arrSM[3]) {
        //                 listMetaMap.push({ id: Number(arrSM[3]) } as IMeta);
        //             }
        //             if(arrSM[4]) {
        //                 listMetaMap.push({ id: Number(arrSM[4]) } as IMeta);
        //             }
        //             if(arrSM[5]) {
        //                 listMetaMap.push({ id: Number(arrSM[5]) } as IMeta);
        //             }
        //             if(arrSM[6]) {
        //                 listMetaMap.push({ id: Number(arrSM[6]) } as IMeta);
        //             }

        //             studyMapList.push(studyMap);                                
        //         }    
        //     }
        // }

        const keyType = (_item[39] && _item[39].trim().length > 0) ? "Grammar" : (_item[41] && _item[41].trim().length > 0) ? "Expression" : undefined;

        const _data = {

            "id": _item[21] && Number(_item[21]) > 0 ? Number(_item[21]) : undefined,
            "brand_id": _item[0],
            "type_1": "문장",
            "type_2": undefined, 
            "name": _item[24], 
            "is_active": _item[16] === "1" ? true : false, 
            "is_publicOpen": _item[17] === "1" ? true : false, 
            "creator": _item[13],
            // "description": LR_description_val,
            // "used_in": LR_usedin_val,
            "curriBook": _item[8],
            "curriUnit1": _item[9],
            "curriUnit2": _item[10],
            "curriUnit3": _item[11],
            "curriUnit4": _item[12],
            "metaMap": listMetaMap, 

            "speaker": _item[23],

            "contents_entry": _item[24],
            "contents_multiLang": _item[26],

            "info1": _item[34],
            "info2": _item[35],
            "info3": _item[36],
            "chunk1": _item[37],
            "chunk2": _item[38],

            "key_type": keyType,
            "key_title": keyType === "Grammar" ? _item[39] : keyType === "Expression" ? _item[41] : undefined,
            "key_desc": keyType === "Grammar" ? _item[40] : keyType === "Expression" ? _item[42] : undefined,

            "key_info": _item[50],

            "exam_sentences": examList,
            // "articles": articleList,
            // "studyMapListString": JSON.stringify(studyMapList),                                

            // "medias": _mediasList,
        };

        // logger.log("data", _data);

        const result = await util.textForSave(_data, _data.id ? "update" : "insert");
        // logger.log("_onSave", _data, result);

        return result;
        
    }

    async function insertScript(arrItem: string[][]) {

        const _item = arrItem[0];

        const listMetaMap : IMeta[] = [];
        listMetaMap.push({id: Number(_item[6])} as IMeta);
        listMetaMap.push({id: Number(_item[7])} as IMeta);
        // listMetaMap.push({id: Number(_item[53])} as IMeta);

        const articles = _item[52];
        const articleList : IArticle[] = [];
        if(articles && articles.length > 1) {
            const arrArticles = articles.split(",");
            if(arrArticles.length > 0) {
                arrArticles.map((item) => {
                    articleList.push({id: Number(item)} as IArticle);
                });                                
            }
        }

        // const studyMapList : IStudyMap[] = [];
        // for (let i = 54; i < 64; i++) {
        //     const sm = _item[i];
        //     if(sm && sm.length > 1) {
        //         const arrSM = sm.split(",");
        //         if(arrSM.length > 0) {

        //             const studyMap : IStudyMap = {
        //                 depth0: { id: Number(arrSM[0]) } as IMeta,
        //                 depth1: { id: Number(arrSM[1]) } as IMeta,
        //                 depth2: { id: Number(arrSM[2]) } as IMeta,
        //                 depth3: { id: Number(arrSM[3]) } as IMeta,
        //                 depth4: { id: Number(arrSM[4]) } as IMeta,
        //                 depth5: { id: Number(arrSM[5]) } as IMeta,
        //                 depth6: { id: Number(arrSM[6]) } as IMeta,
        //                 id: 0,
        //             }

        //             if(arrSM[0]) {
        //                 listMetaMap.push({ id: Number(arrSM[0]) } as IMeta);
        //             }
        //             if(arrSM[1]) {
        //                 listMetaMap.push({ id: Number(arrSM[1]) } as IMeta);
        //             }
        //             if(arrSM[2]) {
        //                 listMetaMap.push({ id: Number(arrSM[2]) } as IMeta);
        //             }
        //             if(arrSM[3]) {
        //                 listMetaMap.push({ id: Number(arrSM[3]) } as IMeta);
        //             }
        //             if(arrSM[4]) {
        //                 listMetaMap.push({ id: Number(arrSM[4]) } as IMeta);
        //             }
        //             if(arrSM[5]) {
        //                 listMetaMap.push({ id: Number(arrSM[5]) } as IMeta);
        //             }
        //             if(arrSM[6]) {
        //                 listMetaMap.push({ id: Number(arrSM[6]) } as IMeta);
        //             }

        //             studyMapList.push(studyMap);                                
        //         }    
        //     }
        // }
    
        const _scriptList : IScript[] = [];
        for (let i = 0; i < arrItem.length; i++) {
            const item = arrItem[i];

            const data = {
                entry: item[24], 
                eng: item[25],
                kor: item[26],
                jpn: item[27],
                ch1: item[28],
                ch2: item[29],
                vtn: item[30],
                spa: item[31],
                por: item[32],
                ara: item[33],
            };
            await insertMultiLang(data);

            const _script : IScript = {
                pkey: item[18] ? item[18] : "",
                skey: item[21] ? item[21] : "",
                type: item[3] === "혼합형" && item[23] && item[23].length > 0 ? "대화형" : "일반형",
                speaker: item[23] ? item[23] : "",
                entry: item[24] ? item[24] : "",
                multiLang: item[26] ? item[26] : "",
                startTime: item[45] ? item[45] : "",
                endTime: item[46] ? item[46] : "",
                akey: item[51] ? item[51] : "",
                summary: item[19] ? item[19] : "",
                exceptEmpty: item[53] ? item[53] : "N",
            }
            _scriptList.push(_script as IScript);    
        }

        const id = _item[4];
        let bUpdate = false;
        if(id && Number(id) > -1) {
            bUpdate = true;
        }

        const _data = {

            "id": bUpdate ? id : undefined,
            "brand_id": _item[0],
            "type_1": _item[2],
            "type_2": _item[3], 
            "name": _item[5], 
            "is_active": _item[16] === "1" ? true : false, 
            "is_publicOpen": _item[17] === "1" ? true : false, 
            "creator": _item[13],
            // "description": LR_description_val,
            // "used_in": LR_usedin_val,
            "curriBook": _item[8],
            "curriUnit1": _item[9],
            "curriUnit2": _item[10],
            "curriUnit3": _item[11],
            "curriUnit4": _item[12],
            "metaMap": listMetaMap, 

            "contents_image": _item[43] ? _item[43] : "",
            "contents_audio": _item[44] ? _item[44] : "",
            "contents_video": _item[47] ? _item[47] : "",

            // "studyMapListString": JSON.stringify(studyMapList),                                
            "dialog": _scriptList,

            "articles": articleList,

        };

        // logger.log("data", _data);

        const result = await util.textForSave(_data, bUpdate ? "update" : "insert");
        logger.log("insertScript", _data, result);
        
    }

    async function onExcelProccessing(e: ProgressEvent<FileReader>, file: File) {

        if(e.target) {

            setFileUploading(true);

            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            const sheetName0 = dataParse[0] as string[];
            const sheetName1 = dataParse[1] as string[];
            // logger.log("dataParse", file, sheetName0, sheetName1);  
            if((sheetName0[0] && sheetName0[0].trim() === "텍스트_지문") || (sheetName1[0] && sheetName1[0].trim() === "텍스트_지문")) {
                
                const arrRows = [];
                for (let i = 4; i < dataParse.length; i++) {
                    const arrItem = dataParse[i] as string[];
                    if(arrItem[20] === "Y") {
                        const result = await insertSentenceForScript(arrItem);

                        if(arrItem[21] && Number(arrItem[21]) > 0) {
                        } else {
                            if(result) {
                                arrItem[21] = String((result as IText).id);
                            }    
                        }

                        // logger.log("arrItem", arrItem, result);  
                    }
                    arrRows.push(arrItem);
                }
                await insertScript(arrRows);
                
                logger.log("arrRows", arrRows);  

            } else {

                logger.log("dataParse", dataParse);  

                for (let i = 4; i < dataParse.length; i++) {

                    const item = dataParse[i];

                    const _item = item as string[];

                    // logger.log("dataParse", _item);  

                    const type = _item[2];

                    if(type === "어휘") {                

                        const data = {
                            entry: _item[19], 
                            eng: _item[20],
                            kor: _item[21],
                            jpn: _item[22],
                            ch1: _item[23],
                            ch2: _item[24],
                            vtn: _item[25],
                            spa: _item[26],
                            por: _item[27],
                            ara: _item[28],
                        };
                        await insertMultiLang(data);

                        const listMetaMap : IMeta[] = [];
                        listMetaMap.push({id: Number(_item[6])} as IMeta);
                        listMetaMap.push({id: Number(_item[7])} as IMeta);
                        // listMetaMap.push({id: Number(_item[36])} as IMeta);

                        const examList : IText[] = [];
                        if(_item[34]) {
                            const exams = String(_item[34]);
                            if(exams && exams.length > 1) {
                                const arrExams = exams.split(",");
                                if(arrExams.length > 0) {
                                    arrExams.map((item) => {
                                        examList.push({id: Number(item)} as IText);
                                    });                                
                                }
                            }    
                        }

                        const articleList : IArticle[] = [];
                        if(_item[35]) {
                            const articles = String(_item[35]);
                            if(articles && articles.length > 1) {
                                const arrArticles = articles.split(",");
                                if(arrArticles.length > 0) {
                                    arrArticles.map((item) => {
                                        articleList.push({id: Number(item)} as IArticle);
                                    });                                
                                }
                            }    
                        }

                        const studyMapList : IStudyMap[] = [];
    
                        // for (let i = 37; i < 47; i++) {
                        //     const sm = _item[i];
                        //     if(sm && sm.length > 1) {
                        //         const arrSM = sm.split(",");
                        //         if(arrSM.length > 0) {

                        //             const studyMap : IStudyMap = {
                        //                 depth0: { id: Number(arrSM[0]) } as IMeta,
                        //                 depth1: { id: Number(arrSM[1]) } as IMeta,
                        //                 depth2: { id: Number(arrSM[2]) } as IMeta,
                        //                 depth3: { id: Number(arrSM[3]) } as IMeta,
                        //                 depth4: { id: Number(arrSM[4]) } as IMeta,
                        //                 depth5: { id: Number(arrSM[5]) } as IMeta,
                        //                 depth6: { id: Number(arrSM[6]) } as IMeta,
                        //                 id: 0,
                        //             }
                
                        //             if(arrSM[0]) {
                        //                 listMetaMap.push({ id: Number(arrSM[0]) } as IMeta);
                        //             }
                        //             if(arrSM[1]) {
                        //                 listMetaMap.push({ id: Number(arrSM[1]) } as IMeta);
                        //             }
                        //             if(arrSM[2]) {
                        //                 listMetaMap.push({ id: Number(arrSM[2]) } as IMeta);
                        //             }
                        //             if(arrSM[3]) {
                        //                 listMetaMap.push({ id: Number(arrSM[3]) } as IMeta);
                        //             }
                        //             if(arrSM[4]) {
                        //                 listMetaMap.push({ id: Number(arrSM[4]) } as IMeta);
                        //             }
                        //             if(arrSM[5]) {
                        //                 listMetaMap.push({ id: Number(arrSM[5]) } as IMeta);
                        //             }
                        //             if(arrSM[6]) {
                        //                 listMetaMap.push({ id: Number(arrSM[6]) } as IMeta);
                        //             }

                        //             studyMapList.push(studyMap);                                
                        //         }    
                        //     }
                        // }

                        const id = _item[4];
                        let bUpdate = false;
                        if(id && Number(id) > -1) {
                            bUpdate = true;
                        }
                        const _data = {

                            "id": bUpdate ? id : undefined,
                            "brand_id": _item[0],
                            "type_1": _item[2],
                            // "type_2": _item[3], 
                            "name": _item[5], 
                            "is_active": _item[16] === "1" ? true : false, 
                            "is_publicOpen": _item[17] === "1" ? true : false, 
                            "creator": _item[13],
                            // "description": LR_description_val,
                            // "used_in": LR_usedin_val,
                            "curriBook": _item[8],
                            "curriUnit1": _item[9],
                            "curriUnit2": _item[10],
                            "curriUnit3": _item[11],
                            "curriUnit4": _item[12],
                            "metaMap": listMetaMap, 
                
                            "parts": _item[18],
                
                            "contents_entry": _item[19] ? _item[19] : "",
                            "contents_multiLang": _item[21] ? _item[21] : "",
                            "contents_def": _item[29] ? _item[29] : "",
                            "contents_pron": _item[30] ? _item[30] : "",
                            "contents_image": _item[31] ? _item[31] : "",
                            "contents_audio": _item[32] ? _item[32] : "",
                            "contents_video": _item[33] ? _item[33] : "",
                
                            "exam_sentences": examList,
                            "articles": articleList,
                            // "studyMapListString": JSON.stringify(studyMapList),
                
                        };

                        logger.log("data", _data);

                        await _onSave(_data, bUpdate ? "update" : "insert");
                        
                    } else if(type === "문장") {

                        const data = {
                            entry: _item[19], 
                            eng: _item[20],
                            kor: _item[21],
                            jpn: _item[22],
                            ch1: _item[23],
                            ch2: _item[24],
                            vtn: _item[25],
                            spa: _item[26],
                            por: _item[27],
                            ara: _item[28],
                        };
                        await insertMultiLang(data);

                        const listMetaMap : IMeta[] = [];
                        listMetaMap.push({id: Number(_item[6])} as IMeta);
                        listMetaMap.push({id: Number(_item[7])} as IMeta);
                        // listMetaMap.push({id: Number(_item[44])} as IMeta);

                        const examList : IText[] = [];
                        if(_item[42]) {
                            const exams = String(_item[42]);
                            if(exams && exams.length > 1) {
                                const arrExams = exams.split(",");
                                if(arrExams.length > 0) {
                                    arrExams.map((item) => {
                                        examList.push({id: Number(item)} as IText);
                                    });                                
                                }
                                // logger.log("exams", arrExams);
                            }
                            // logger.log("exams", exams, examList);    
                        }

                        const articleList : IArticle[] = [];
                        if(_item[43]) {
                            const articles = String(_item[43]);
                            if(articles && articles.length > 1) {
                                const arrArticles = articles.split(",");
                                if(arrArticles.length > 0) {
                                    arrArticles.map((item) => {
                                        articleList.push({id: Number(item)} as IArticle);
                                    });                                
                                }
                            }    
                        }

                        const studyMapList : IStudyMap[] = [];
    
                        // for (let i = 45; i < 55; i++) {
                        //     const sm = _item[i];
                        //     if(sm && sm.length > 1) {
                        //         const arrSM = sm.split(",");
                        //         if(arrSM.length > 0) {

                        //             const studyMap : IStudyMap = {
                        //                 depth0: { id: Number(arrSM[0]) } as IMeta,
                        //                 depth1: { id: Number(arrSM[1]) } as IMeta,
                        //                 depth2: { id: Number(arrSM[2]) } as IMeta,
                        //                 depth3: { id: Number(arrSM[3]) } as IMeta,
                        //                 depth4: { id: Number(arrSM[4]) } as IMeta,
                        //                 depth5: { id: Number(arrSM[5]) } as IMeta,
                        //                 depth6: { id: Number(arrSM[6]) } as IMeta,
                        //                 id: 0,
                        //             }
                
                        //             if(arrSM[0]) {
                        //                 listMetaMap.push({ id: Number(arrSM[0]) } as IMeta);
                        //             }
                        //             if(arrSM[1]) {
                        //                 listMetaMap.push({ id: Number(arrSM[1]) } as IMeta);
                        //             }
                        //             if(arrSM[2]) {
                        //                 listMetaMap.push({ id: Number(arrSM[2]) } as IMeta);
                        //             }
                        //             if(arrSM[3]) {
                        //                 listMetaMap.push({ id: Number(arrSM[3]) } as IMeta);
                        //             }
                        //             if(arrSM[4]) {
                        //                 listMetaMap.push({ id: Number(arrSM[4]) } as IMeta);
                        //             }
                        //             if(arrSM[5]) {
                        //                 listMetaMap.push({ id: Number(arrSM[5]) } as IMeta);
                        //             }
                        //             if(arrSM[6]) {
                        //                 listMetaMap.push({ id: Number(arrSM[6]) } as IMeta);
                        //             }

                        //             studyMapList.push(studyMap);                                
                        //         }    
                        //     }
                        // }

                        const keyType = (_item[34] && _item[34].trim().length > 0) ? "Grammar" : (_item[36] && _item[36].trim().length > 0) ? "Expression" : undefined;

                        const id = _item[4];
                        const _data = {

                            "id": id && Number(id) > -1 ? id : undefined,
                            "brand_id": _item[0],
                            "type_1": _item[2],
                            // "type_2": _item[3], 
                            "name": _item[5], 
                            "is_active": _item[16] === "1" ? true : false, 
                            "is_publicOpen": _item[17] === "1" ? true : false, 
                            "creator": _item[13],
                            // "description": LR_description_val,
                            // "used_in": LR_usedin_val,
                            "curriBook": _item[8],
                            "curriUnit1": _item[9],
                            "curriUnit2": _item[10],
                            "curriUnit3": _item[11],
                            "curriUnit4": _item[12],
                            "metaMap": listMetaMap, 
                
                            "speaker": _item[18] ? _item[18] : "",
                
                            "contents_entry": _item[19] ? _item[19] : "",
                            "contents_multiLang": _item[21] ? _item[21] : "",

                            "contents_image": _item[39] ? _item[39] : "",
                            "contents_audio": _item[40] ? _item[40] : "",
                            "contents_video": _item[41] ? _item[41] : "",
                            "contents_audioAnalysis": _item[44] ? _item[44] : "",
            
                            "info1": _item[29] ? _item[29] : "",
                            "info2": _item[30] ? _item[30] : "",
                            "info3": _item[31] ? _item[31] : "",
                            "chunk1": _item[32] ? _item[32] : "",
                            "chunk2": _item[33] ? _item[33] : "",

                            "key_type": keyType,
                            "key_title": keyType === "Grammar" ? _item[34] ? _item[34] : "" : keyType === "Expression" ? _item[36] ? _item[36] : "" : undefined,
                            "key_desc": keyType === "Grammar" ? _item[35] ? _item[35] : "" : keyType === "Expression" ? _item[37] ? _item[37] : "" : undefined,

                            "key_info": _item[38] ? _item[38] : "",
                
                            "exam_sentences": examList,
                            "articles": articleList,

                            // "studyMapListString": JSON.stringify(studyMapList),                                
                
                            // "medias": _mediasList,
                        };

                        logger.log("data", _data);

                        await _onSave(_data, id && Number(id) > -1 ? "update" : "insert");

                    }                       
                }
            }

            setFileUploading(false);
            props.OnClose();
        }
    }

    async function _onSave(data: any, type: string) {
        const result = await util.textForSave(data, type);
        logger.log("_onSave", data, result);
    }

    async function onFileUploadForSave() {

        if(!fileContent) {
            props.showModal("", "엑셀 파일을 등록해주세요.");
            return;
        }

        logger.log("onFileUploadForSave", fileContent.file);      

        var reader = new FileReader();
        reader.onload = (e) => onExcelProccessing(e, fileContent.file);
        reader.readAsBinaryString(fileContent.file)

        // const brandId = props.brand.id;
        // const filepath = "upload/"+brandId+"/"+util.getDate().replaceAll(".", "")+"/";

        // logger.log("onFileUploadForSave started!!!");      

        // setFileUploading(true);

        // const result = await util.UploadFile(fileContent.file!, filepath, props.type);      
        // logger.log("onFileUploadForSave", fileContent, result);      

        // logger.log("onFileUploadForSave finished!!!");  
        
        // setFileUploading(false);

        // props.showModal("", "등록이 완료 되었습니다.");
        // props.OnClose();                

    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  
        logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const _url = e.target.files![0];
        const _content : ILibraryFile = {
            type: _url.type,
            file: _url,
            url: _url.name,
            thumbnail: _url.name,
            isDelete: false,
            isUploaded: false
        }
        logger.log("handleChange", _content);
    
        setFileContent(_content);
    }

    function onDownload(type : string) {

        let url = "";
        if(type === "어휘") {
            url = common.S3Server + "upload/1/20240201/%E1%84%90%E1%85%A6%E1%86%A8%E1%84%89%E1%85%B3%E1%84%90%E1%85%B3_%E1%84%8B%E1%85%A5%E1%84%92%E1%85%B1.xlsx";
        } else if(type === "문장") {
            url = common.S3Server + "upload/1/20240201/%E1%84%90%E1%85%A6%E1%86%A8%E1%84%89%E1%85%B3%E1%84%90%E1%85%B3_%E1%84%86%E1%85%AE%E1%86%AB%E1%84%8C%E1%85%A1%E1%86%BC.xlsx";
        } else if(type === "지문_대화형") {
            url = common.S3Server + "upload/1/20240201/%E1%84%90%E1%85%A6%E1%86%A8%E1%84%89%E1%85%B3%E1%84%90%E1%85%B3_%E1%84%8C%E1%85%B5%E1%84%86%E1%85%AE%E1%86%AB.xlsx";
        } else if(type === "지문_일반형") {
            url = common.S3Server + "upload/1/20240201/%E1%84%90%E1%85%A6%E1%86%A8%E1%84%89%E1%85%B3%E1%84%90%E1%85%B3_%E1%84%8C%E1%85%B5%E1%84%86%E1%85%AE%E1%86%AB.xlsx";
        } else if(type === "지문_혼합형") {
            url = common.S3Server + "upload/1/20240201/%E1%84%90%E1%85%A6%E1%86%A8%E1%84%89%E1%85%B3%E1%84%90%E1%85%B3_%E1%84%8C%E1%85%B5%E1%84%86%E1%85%AE%E1%86%AB.xlsx";
        }

        window.open(url, "_blank", "noopener, noreferrer");    
    }

    
    return (
        <div>

            <div>

                <h4>일괄 등록</h4>

                {/* <!-- table --> */}
				<table>
					<caption className="sr-only">이미지 관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row" className="w-56">샘플 파일 다운로드</th>
							<td className="text-left">
								<div>
									<button type="button" className="btn-white-s" onClick={() => onDownload("어휘")}><span className="file-down">어휘</span></button>
									<button type="button" className="btn-white-s ml-2" onClick={() => onDownload("문장")}><span className="file-down">문장</span></button>
									<button type="button" className="btn-white-s ml-2" onClick={() => onDownload("지문_대화형")}><span className="file-down">지문</span></button>
									{/* <button type="button" className="btn-white-s ml-2" onClick={() => onDownload("지문_일반형")}><span className="file-down">지문(일반형)</span></button>
									<button type="button" className="btn-white-s ml-2" onClick={() => onDownload("지문_혼합형")}><span className="file-down">지문(혼합형)</span></button> */}
								</div>
								{/* <div>
									<button type="button" className="btn-white-s"><span className="file-down">ST_1</span></button>
									<button type="button" className="btn-white-s"><span className="file-down">ST_2</span></button>
									<button type="button" className="btn-white-s"><span className="file-down">ST_3</span></button>
									<button type="button" className="btn-white-s"><span className="file-down">ST_4</span></button>
									<button type="button" className="btn-white-s"><span className="file-down">ST_5</span></button>
								</div> */}
							</td>
						</tr>
						<tr>
							<th scope="row"><label htmlFor="file-input" className="mr-0">파일 찾기</label></th>
							<td className="text-left">
								<label htmlFor="file-input" className="sr-only">파일찾기</label>
								{/* <input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file"/> */}
								<input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file" onChange={(e) => handleChange(e)}/>
							</td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
				
				<div className="relative mt-5">
					<div className="flex justify-between">
						<button type="button" className="btn-white" onClick={OnClose}>목록</button>
						<button type="button" className="btn-sky" onClick={onFileUploadForSave}>저장</button>
					</div>
				</div>

            </div>

            {fileUploading && <>
                <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="dim">
                        <CircularProgress size={40} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: `${-40/2}px`, marginLeft: `${-40/2}px` }} />
                    </div>
                </div>
            </>}

        </div>
    );
}

export default LibraryBulkRegister;